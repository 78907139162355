import React, { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Loading from "components/common/Loading";
import { APP_PREFIX_PATH } from "configs/appConfig";

// AppViews component
export const AppViews = () => {
  const history = useHistory();
  const authData = useSelector((state: { auth: any }) => state.auth);

  useEffect(() => {
    // Redirect to authentication if not logged in and prepared
    if (authData.isPrepared && !authData.isLoggedIn) {
      history.push("/auth");
    }
  }, [authData, history]);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {/* Home */}
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />

        {/* Devices */}
        <Route
          path={`${APP_PREFIX_PATH}/devices/:id`}
          component={lazy(() => import(`./devices/deviceDetails`))}
        />
        <Route path={`${APP_PREFIX_PATH}/devices`} component={lazy(() => import(`./devices`))} />

        {/* Crypto */}
        <Route
          path={`${APP_PREFIX_PATH}/crypto/:id`}
          component={lazy(() => import(`./crypto/cryptoDetails`))}
        />
        <Route path={`${APP_PREFIX_PATH}/crypto`} component={lazy(() => import(`./crypto`))} />

        {/* Policies */}
        <Route
          path={`${APP_PREFIX_PATH}/policies/create`}
          component={lazy(() => import(`./eligibility/components/createPolicies`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/policies/update/:id`}
          component={lazy(() => import(`./eligibility/components/createPolicies`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/policies/:id`}
          component={lazy(() => import(`./eligibility/policyDetails`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/policies`}
          component={lazy(() => import(`./eligibility`))}
        />

        {/* Provisioning */}
        <Route
          path={`${APP_PREFIX_PATH}/provisioning`}
          component={lazy(() => import(`./provisioning`))}
        />

        {/* Users */}
        <Route
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./users/MainView`))}
        />

        {/* User Groups */}
        <Route
          path={`${APP_PREFIX_PATH}/userGroups`}
          component={lazy(() => import(`./userGroups/userGroups`))}
        />

        {/* Permissions */}
        <Route
          path={`${APP_PREFIX_PATH}/permissions`}
          component={lazy(() => import(`./permissions/permissions`))}
        />

        {/* Certificates */}
        <Route
          path={`${APP_PREFIX_PATH}/certificates/:id`}
          component={lazy(() => import(`./certificates/certificatesDetails`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/certificates`}
          component={lazy(() => import(`./certificates`))}
        />

        {/* Profile */}
        <Route
          path={`${APP_PREFIX_PATH}/profile`}
          component={lazy(() => import(`./profile/profile`))}
        />

        {/* Default redirect */}
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
