const uploadFile = (req) => {
  let reqUrl = req.url; // Store the URL from the request
  const reqObj = { method: req.type }; // Create a request object with the request method

  if (req.type === "POST" || req.type === "PUT") {
    // For POST or PUT requests
    if (req.fileName) {
      // If the request has a file name
      const contentType = req.contentType ?? "text/csv"; // Get the content type from the request or default to 'text/csv'
      reqObj.body = req.data.originFileObj; // Set the request body as the originFileObj from the request data
      reqObj.headers = { "Content-Type": contentType }; // Set the request headers with the content type
    } else {
      reqObj.body = req.data.originFileObj; // Set the request body as the originFileObj from the request data
    }
  }

  return fetch(reqUrl, reqObj) // Perform the fetch request with the URL and request object
    .then((res) => ({ res })) // Return the response as an object
    .catch((error) => ({ error })); // Return any error that occurred as an object
};

export default uploadFile;
