import {
  CREATE_CRYPTO_ASSET,
  CREATE_CRYPTO_ASSET_SUCCESS,
  DELETE_CRYPTO_ASSETS,
  DELETE_CRYPTO_ASSET_SUCCESS,
  FILE_UPLOAD_INIT,
  GET_CRYPTO_STATS,
  GET_CRYPTO_STATS_FAILURE,
  GET_CRYPTO_STATS_SUCCESS,
  RETRIEVE_CRYPTO_ASSETS,
  RETRIEVE_CRYPTO_ASSETS_FAIL,
  RETRIEVE_CRYPTO_ASSETS_SUCCESS,
  RETRIEVE_CRYPTO_ASSET_SUCCESS,
  SEARCH_CRYPTO_ASSET,
  SEARCH_CRYPTO_ASSET_SUCCESS,
  UPDATE_CRYPTO_ASSET,
  UPDATE_CRYPTO_ASSET_SUCCESS,
  GET_AUDIT_LOGS,
  GET_AUDIT_LOGS_SUCCESS,
  GET_AUDIT_LOGS_FAILURE,
  RETRIEVE_SERVICE_PROVIDERS,
  RETRIEVE_SERVICE_PROVIDERS_SUCCESS,
  RETRIEVE_SERVICE_PROVIDERS_FAILURE,
  CRYPTO_BULK_UPLOAD,
  CRYPTO_BULK_UPLOAD_SUCCESS,
  CRYPTO_BULK_UPLOAD_FAILURE,
  ELIGIBILITY_SEARCH_CRYPTO_ASSET,
  ELIGIBILITY_SEARCH_CRYPTO_ASSET_SUCCESS,
} from "../constants/Crypto";

const initialState = {
  // Initial state for crypto assets
  loading: false, // Flag indicating if crypto assets are being loaded
  error: false, // Flag indicating if an error occurred
  total: 0, // Total number of crypto assets
  cryptoAssets: [], // Array of crypto asset objects
  selectedCryptoAsset: {}, // Selected crypto asset object
  serviceProviders: [], // Array of service provider objects
  message: "", // Message related to crypto asset operations
  description: [], // Array of descriptions
  stats: {}, // Statistics related to crypto assets
  devices: [], // Array of devices
  devicesLoading: false, // Flag indicating if devices are being loaded
  uploading: false, // Flag indicating if a bulk upload is in progress
};

function cryptoReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CRYPTO_ASSET:
      // Create crypto asset action
      return { ...state, loading: true };

    case CREATE_CRYPTO_ASSET_SUCCESS:
      // Create crypto asset success action
      return {
        ...state,
        loading: false,
        cryptoAssets: [...state.cryptoAssets, payload.data],
        message: payload.message,
      };

    case RETRIEVE_CRYPTO_ASSETS:
      // Retrieve crypto assets action
      return { ...state, loading: true };

    case RETRIEVE_CRYPTO_ASSETS_FAIL:
      // Failed to retrieve crypto assets action
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.data.message || "",
      };

    case RETRIEVE_CRYPTO_ASSETS_SUCCESS:
      // Successfully retrieved crypto assets action
      return { ...state, loading: false, cryptoAssets: payload.data.crypto };

    case CRYPTO_BULK_UPLOAD:
      // Crypto bulk upload action
      return { ...state, uploading: true };

    case CRYPTO_BULK_UPLOAD_SUCCESS:
      // Crypto bulk upload success action
      return { ...state, uploading: false };

    case CRYPTO_BULK_UPLOAD_FAILURE:
      // Crypto bulk upload failure action
      return { ...state, uploading: false };

    case RETRIEVE_SERVICE_PROVIDERS:
      // Retrieve service providers action
      return { ...state, loading: true };

    case RETRIEVE_SERVICE_PROVIDERS_FAILURE:
      // Failed to retrieve service providers action
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.data.message || "",
      };

    case RETRIEVE_SERVICE_PROVIDERS_SUCCESS:
      // Successfully retrieved service providers action
      return {
        ...state,
        loading: false,
        serviceProviders: payload.data.serviceProviders,
      };

    case UPDATE_CRYPTO_ASSET:
      // Update crypto asset action
      return {
        ...state,
        loading: true,
        cryptoAssets: state.cryptoAssets.map((crypto: any) => {
          if (crypto.id === payload.data.id) {
            return {
              ...crypto,
              ...payload.data,
            };
          } else {
            return crypto;
          }
        }),
      };

    case UPDATE_CRYPTO_ASSET_SUCCESS:
      // Update crypto asset success action
      return { ...state, loading: false, message: payload.message };

    case DELETE_CRYPTO_ASSETS:
      // Delete crypto assets action
      return {
        ...state,
        loading: true,
        cryptoAssets: state.cryptoAssets.map((crypto: any, index) => {
          if (crypto.id === payload.id) {
            return {
              ...state.cryptoAssets.splice(index, 1),
            };
          } else {
            return crypto;
          }
        }),
      };

    case DELETE_CRYPTO_ASSET_SUCCESS:
      // Delete crypto asset success action
      return {
        ...state,
        loading: false,
        message: payload.data.message,
        description: payload.data.description,
      };

    case SEARCH_CRYPTO_ASSET:
      // Search crypto asset action
      return { ...state, loading: true };

    case SEARCH_CRYPTO_ASSET_SUCCESS:
      // Successfully searched crypto assets action
      return {
        ...state,
        loading: false,
        cryptoAssets: payload.data.cryptos,
      };

    case RETRIEVE_CRYPTO_ASSET_SUCCESS:
      // Successfully retrieved crypto asset action
      return {
        ...state,
        loading: false,
        selectedCryptoAsset: payload.data.crypto,
      };

    case FILE_UPLOAD_INIT:
      // File upload initialization action
      return { ...state, loading: true };

    case GET_CRYPTO_STATS:
      // Get crypto asset statistics action
      return { ...state, loading: true };

    case GET_CRYPTO_STATS_SUCCESS:
      // Successfully retrieved crypto asset statistics action
      return { ...state, loading: false, stats: payload };

    case GET_CRYPTO_STATS_FAILURE:
      // Failed to retrieve crypto asset statistics action
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.data.message || "",
      };

    case GET_AUDIT_LOGS:
      // Get audit logs action
      return { ...state, auditLogsLoading: true };

    case GET_AUDIT_LOGS_FAILURE:
      // Failed to retrieve audit logs action
      return {
        ...state,
        auditLogsLoading: false,
        error: true,
        message: payload.message || "",
        auditLogs: [],
      };

    case GET_AUDIT_LOGS_SUCCESS:
      // Successfully retrieved audit logs action
      return {
        ...state,
        auditLogsLoading: false,
        auditLogs: payload.data.auditLogs,
      };

    case ELIGIBILITY_SEARCH_CRYPTO_ASSET:
      // Eligibility search crypto asset action
      return { ...state, loading: true };

    case ELIGIBILITY_SEARCH_CRYPTO_ASSET_SUCCESS:
      // Successfully performed eligibility search for crypto assets action
      return {
        ...state,
        loading: false,
        cryptoAssets: payload.data.cryptos,
      };

    default:
      return state;
  }
}

export default cryptoReducer;
