import {
  CREATE_CERTIFICATE,
  CREATE_CERTIFICATE_SUCCESS,
  RETRIEVE_CERTIFICATES,
  RETRIEVE_CERTIFICATES_SUCCESS,
  RETRIEVE_CERTIFICATES_FAILIURE,
  RETRIEVE_CERTIFICATE,
  RETRIEVE_CERTIFICATE_SUCCESS,
  RETRIEVE_CERTIFICATE_FAILIURE,
  RETRIEVE_CERTIFICATE_STATUS,
  RETRIEVE_CERTIFICATE_STATUS_SUCCESS,
  RETRIEVE_CERTIFICATE_STATUS_FAILIURE,
  SEARCH_CERTIFICATE_SUCCESS,
  SEARCH_CERTIFICATE,
} from "../constants/Certificate";
import { createAction } from "redux-act";

export const createCertificate = createAction<any | undefined>(CREATE_CERTIFICATE); // Action creator for creating a certificate
export const createCertificateSuccess = createAction<any | undefined>(CREATE_CERTIFICATE_SUCCESS); // Action creator for successful certificate creation

export const retriveCertificates = createAction<any | undefined>(RETRIEVE_CERTIFICATES); // Action creator for retrieving certificates
export const retriveCertificatesSuccess = createAction<any | undefined>(
  RETRIEVE_CERTIFICATES_SUCCESS
); // Action creator for successful certificate retrieval
export const retriveCertificatesFailiure = createAction<any | undefined>(
  RETRIEVE_CERTIFICATES_FAILIURE
); // Action creator for handling failed certificate retrieval

export const retriveCertificate = createAction<any | undefined>(RETRIEVE_CERTIFICATE); // Action creator for retrieving a single certificate
export const retriveCertificateSuccess = createAction<any | undefined>(
  RETRIEVE_CERTIFICATE_SUCCESS
); // Action creator for successful single certificate retrieval
export const retriveCertificateFailiure = createAction<any | undefined>(
  RETRIEVE_CERTIFICATE_FAILIURE
); // Action creator for handling failed single certificate retrieval

export const retriveCertificateStatus = createAction<any | undefined>(RETRIEVE_CERTIFICATE_STATUS); // Action creator for retrieving certificate status
export const retriveCertificateStatusSuccess = createAction<any | undefined>(
  RETRIEVE_CERTIFICATE_STATUS_SUCCESS
); // Action creator for successful certificate status retrieval
export const retriveCertificateStatusFailiure = createAction<any | undefined>(
  RETRIEVE_CERTIFICATE_STATUS_FAILIURE
); // Action creator for handling failed certificate status retrieval

export const searchCertificate = createAction<any | undefined>(SEARCH_CERTIFICATE); // Action creator for searching certificates
export const searchCertificateSuccess = createAction<any | undefined>(SEARCH_CERTIFICATE_SUCCESS); // Action creator for successful certificate search
