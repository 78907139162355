import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AuthLayout from "layout/auth";
import AppLayout from "layout/app";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/appConfig";
import LanguageWrapper from "components/app/LanguageWrapper";

/**
 * Component that handles routing and renders different layouts based on the path.
 * @param props - The component props.
 * @returns The rendered views based on the path.
 */
export const Views = (props: any) => {
  const { location } = props;
  const direction = "ltr";

  return (
    <LanguageWrapper>
      <Switch>
        {/* Redirect to APP_PREFIX_PATH */}
        <Route exact path="/">
          <Redirect to={APP_PREFIX_PATH} />
        </Route>

        {/* Render AuthLayout for AUTH_PREFIX_PATH */}
        <Route path={AUTH_PREFIX_PATH}>
          <AuthLayout />
        </Route>

        {/* Render AppLayout for APP_PREFIX_PATH */}
        <Route path={APP_PREFIX_PATH}>
          <AppLayout direction={direction} location={location} />
        </Route>
      </Switch>
    </LanguageWrapper>
  );
};

/**
 * Maps the state properties to the component props.
 * @param theme - The theme state.
 * @param auth - The authentication state.
 * @returns The mapped state properties.
 */
const mapStateToProps: any = (theme: any, auth: any) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction };
};

// Connect Views component to Redux store and withRouter for access to location
export default withRouter(connect(mapStateToProps)(Views));
