import {
  CHANGE_ELIGIBILITY_STATUS,
  CHANGE_ELIGIBILITY_STATUS_FAILURE,
  CHANGE_ELIGIBILITY_STATUS_SUCCESS,
  CREATE_ELIGIBILITY_RULE,
  CREATE_ELIGIBILITY_RULE_SUCCESS,
  DELETE_ELIGIBILITY_RULES,
  DELETE_ELIGIBILITY_RULE_SUCCESS,
  GET_ELIGIBILITY_STATS,
  GET_ELIGIBILITY_STATS_FAILURE,
  GET_ELIGIBILITY_STATS_SUCCESS,
  RETRIEVE_ELIGIBILITY_RULE,
  RETRIEVE_ELIGIBILITY_RULES,
  RETRIEVE_ELIGIBILITY_RULES_FAIL,
  RETRIEVE_ELIGIBILITY_RULES_SUCCESS,
  RETRIEVE_ELIGIBILITY_RULE_SUCCESS,
  SEARCH_ELIGIBILITY_RULE,
  SEARCH_ELIGIBILITY_RULE_SUCCESS,
  UPDATE_ELIGIBILITY_RULE,
  UPDATE_ELIGIBILITY_RULE_SUCCESS,
} from "../constants/Eligibility";

const initialState = {
  loading: false, // Flag indicating if eligibility rules are being loaded
  error: false, // Flag indicating if an error occurred
  total: 0, // Total number of eligibility rules
  rules: [], // Array of eligibility rules
  selectedRule: {}, // Selected eligibility rule
  description: [], // Array of descriptions
  stats: {}, // Statistics related to eligibility rules
};

function eligibilityReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ELIGIBILITY_RULE:
      // Create eligibility rule action
      return { ...state, loading: true };

    case CREATE_ELIGIBILITY_RULE_SUCCESS:
      // Successfully created eligibility rule action
      return {
        ...state,
        loading: false,
        rules: [...state.rules, payload.data],
      };

    case RETRIEVE_ELIGIBILITY_RULES:
      // Retrieve eligibility rules action
      return { ...state, loading: true };

    case RETRIEVE_ELIGIBILITY_RULES_FAIL:
      // Failed to retrieve eligibility rules action
      return { ...state, loading: false, error: true };

    case RETRIEVE_ELIGIBILITY_RULES_SUCCESS:
      // Successfully retrieved eligibility rules action
      return { ...state, loading: false, rules: payload.data.rules };

    case UPDATE_ELIGIBILITY_RULE:
      // Update eligibility rule action
      return {
        ...state,
        loading: true,
        rules: state.rules.map((eligibility: any) => {
          if (eligibility.id === payload.data.id) {
            return {
              ...eligibility,
              ...payload.data,
            };
          } else {
            return eligibility;
          }
        }),
      };

    case UPDATE_ELIGIBILITY_RULE_SUCCESS:
      // Successfully updated eligibility rule action
      return { ...state, loading: true };

    case DELETE_ELIGIBILITY_RULES:
      // Delete eligibility rule action
      return {
        ...state,
        loading: true,
        rules: state.rules.map((eligibility: any, index) => {
          if (eligibility.id === payload.id) {
            return {
              ...state.rules.splice(index, 1),
            };
          } else {
            return eligibility;
          }
        }),
      };

    case DELETE_ELIGIBILITY_RULE_SUCCESS:
      // Successfully deleted eligibility rule action
      return { ...state, loading: false, description: payload.data.description };

    case SEARCH_ELIGIBILITY_RULE:
      // Search eligibility rule action
      return { ...state, loading: true };

    case SEARCH_ELIGIBILITY_RULE_SUCCESS:
      // Successfully searched eligibility rules action
      return {
        ...state,
        loading: false,
        rules: payload.data.rules,
        total: payload.data.total,
      };

    case RETRIEVE_ELIGIBILITY_RULE:
      // Retrieve eligibility rule action
      return { ...state, loading: true };

    case RETRIEVE_ELIGIBILITY_RULE_SUCCESS:
      // Successfully retrieved eligibility rule action
      return { ...state, loading: false, selectedRule: payload.data.rule };

    case GET_ELIGIBILITY_STATS:
      // Get eligibility rule statistics action
      return { ...state, loading: true };

    case GET_ELIGIBILITY_STATS_SUCCESS:
      // Successfully retrieved eligibility rule statistics action
      return { ...state, loading: false, stats: payload };

    case GET_ELIGIBILITY_STATS_FAILURE:
      // Failed to retrieve eligibility rule statistics action
      return { ...state, loading: false, error: true, message: payload.data.message || "" };

    case CHANGE_ELIGIBILITY_STATUS:
      // Change eligibility rule status action
      return { ...state, loading: true };

    case CHANGE_ELIGIBILITY_STATUS_SUCCESS:
      // Successfully changed eligibility rule status action
      return { ...state, loading: false };

    case CHANGE_ELIGIBILITY_STATUS_FAILURE:
      // Failed to change eligibility rule status action
      return { ...state, loading: false, error: true, message: payload.data.message || "" };

    default:
      return state;
  }
}

export default eligibilityReducer;
