import React, { useState, useEffect, useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, Layout, Menu, Space, Switch } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from "@ant-design/icons";
import Logo from "./Logo";
import NavSearch from "./NavSearch";
import { toggleCollapsedNav, onMobileNavToggle } from "../../redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "../../constants/ThemeConstant";
import utils from "../../utils";
import { clickLogout } from "../../redux/actions/Auth";
import { APP_PREFIX_PATH } from "configs/appConfig";
import { useHistory } from "react-router-dom";
import { switchTheme } from "redux/actions/ThemeSwitcher";
import { LanguageContext } from "components/app/LanguageWrapper";
import IntlMessage from "components/util/IntlMessage";
import { ToggleMenuConstants } from "constants/languages/Common";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    // direction
  } = props;
  const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
  const context = useContext(LanguageContext);

  const [searchActive, setSearchActive] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const dispatch = useDispatch();
  const themeSwictherData = useSelector((state) => state.themeSwitch);
  const history = useHistory();

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        themeSwictherData.currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  const handleClick = () => history.push(`${APP_PREFIX_PATH}/profile`);

  const handleSignOut = (e) => {
    dispatch(clickLogout());
  };

  const toggleTheme = (theme) => {
    setIsDarkMode(theme);
    dispatch(
      switchTheme({
        theme: theme ? "dark" : "light",
      })
    );
  };

  const switchLanguage = (language) => {
    context.selectAppLocale(language);
  };

  const menu = (
    <Menu>
      <Menu.Item key="2" onClick={handleClick}>
        {setLocale(true, ToggleMenuConstants.Profile)}
      </Menu.Item>
      <Menu.Item key="1" onClick={handleSignOut}>
        {setLocale(true, ToggleMenuConstants.Logout)}
      </Menu.Item>
      <Menu.Item key="3">
        <span style={{ paddingRight: "1rem" }}>
          {setLocale(true, ToggleMenuConstants.DarkMode)}
        </span>
        <Switch
          checked={isDarkMode}
          checkedChildren="On"
          unCheckedChildren="Off"
          onChange={toggleTheme}
        />
      </Menu.Item>
      <Menu.SubMenu title={setLocale(true, ToggleMenuConstants.Language)} key="4">
        <Menu.Item key="l1" onClick={() => switchLanguage("en-US")}>
          {setLocale(true, ToggleMenuConstants.LanguageEn)}
        </Menu.Item>
        <Menu.Item key="l2" onClick={() => switchLanguage("fr-FR")}>
          {setLocale(true, ToggleMenuConstants.LanguageFr)}
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
          </div>
          {/* <div className="nav-right">
            <NavPanel direction={direction} />
          </div> */}
          <NavSearch active={searchActive} close={onSearchClose} />
          <Space direction="vertical">
            <Space wrap>
              <Dropdown overlay={menu} placement="bottomRight">
                <Button className="nav-bar-user" style={{ right: "1rem" }} icon={<UserOutlined />}>
                  {setLocale(true, ToggleMenuConstants.User)}
                </Button>
              </Dropdown>
            </Space>
          </Space>
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
