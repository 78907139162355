import { put, take } from "redux-saga/effects";
import { switchTheme, switchThemeFailiure, switchThemeSuccess } from "redux/actions/ThemeSwitcher";

// Switch theme
export function* switchThemeFunc() {
  while (true) {
    const action = yield take(`${switchTheme}`);

    if (action.payload && !action.err) {
      yield put(switchThemeSuccess(action.payload));

      continue;
    }

    yield put(switchThemeFailiure(action.err));
  }
}
