import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/common/Loading";
import { AUTH_PREFIX_PATH } from "configs/appConfig";

/**
 * Component that handles the routes and lazy loads the corresponding components for authentication views.
 * @returns The rendered authentication views.
 */
export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        {/* Render login component for /login path */}
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./login/index`))} />

        {/* Render login component for /login/otp path */}
        <Route
          path={`${AUTH_PREFIX_PATH}/otp`}
          component={lazy(() => import(`./login/otp/index`))}
        />

        {/* Render forgotPassword component for /forgotPassword path */}
        <Route
          path={`${AUTH_PREFIX_PATH}/forgotPassword`}
          component={lazy(() => import(`./forgotPassword/index`))}
        />

        {/* Render confirm component for /confirm path */}
        <Route
          path={`${AUTH_PREFIX_PATH}/confirm`}
          component={lazy(() => import(`./confirm/index`))}
        />

        {/* Redirect from /auth to /auth/login */}
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
