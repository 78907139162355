import { take, call, put } from "redux-saga/effects";
import {
  createCrypto,
  createCryptoSuccess,
  deleteCryptoAssets,
  deleteCryptoSuccess,
  downloadCryptoAsset,
  fileUploadInit,
  getCryptoStats,
  getCryptoStatsFailure,
  getCryptoStatsSuccess,
  retrieveCrypto,
  retrieveCryptoAsset,
  retrieveCryptoAssetSuccess,
  retrieveCryptoFailure,
  retrieveCryptoSuccess,
  retrieveServiceProviders,
  retrieveServiceProvidersFailure,
  retrieveServiceProvidersSuccess,
  searchCrypto,
  searchCryptoSuccess,
  updateCrypto,
  updateCryptoSuccess,
  getAuditLogs,
  getAuditLogsSuccess,
  getAuditLogsFailure,
  cryptoBulkUpload,
  getCryptoUploadSampleCSV,
  getCryptoUploadSampleCSVSuccess,
  getCryptoUploadSampleCSVFailure,
  downloadSignedBinary,
  cryptoBulkUploadSuccess,
  cryptoBulkUploadFailure,
  eligibilitySearchCrypto,
  eligibilitySearchCryptoSuccess,
} from "../actions/Crypto";
import {
  CREATE_CRYPTO_ASSET_PATH,
  DELETE_CRYPTO_ASSETS_PATH,
  FILE_UPLOAD_INIT_PATH,
  RETRIEVE_CRYPTO_ASSETS_PATH,
  UPDATE_CRYPTO_ASSET_PATH,
  CRYPTO_BINARY_UPLOAD,
  SEARCH_CRYPTO_ASSET_PATH,
  RETRIEVE_CRYPTO_ASSET_PATH,
  GET_CRYPTO_STATS_PATH,
  AUDIT_LOGS_PATH,
  GET_AUDIT_LOGS_PATH,
  RETRIEVE_SERVICE_PROVIDERS_PATH,
  CRYPTO_UPLOAD,
  CREATE_CRYPTO_BULK_PATH,
  CRYPTO_BINARY_UPLOAD_SIGNED,
  ELIGIBILITY_SEARCH_CRYPTO_ASSET_PATH,
} from "../constants/Crypto";
import { DOWNLOAD_ACTION, UPLOAD_ACTION } from "redux/constants/Common";
import fileUpload from "../modules/fileUpload";
import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

// Define binary backend endpoint and common backend endpoint
const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_CRYPTO_BACKEND_DEV}/${path}`
    : process.env.REACT_APP_ENV === "staging"
    ? `${process.env.REACT_APP_CRYPTO_BACKEND_STAGING}/${path}`
    : `${process.env.REACT_APP_CRYPTO_BACKEND_QA}/${path}`;
const commonEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_COMMON_BACKEND_DEV}/${path}`
    : process.env.REACT_APP_ENV === "staging"
    ? `${process.env.REACT_APP_COMMON_BACKEND_STAGING}/${path}`
    : `${process.env.REACT_APP_COMMON_BACKEND_QA}/${path}`;

// Retrieve all crypto assets
export function* retrieveCryptoAPI() {
  while (true) {
    yield take(`${retrieveCrypto}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(RETRIEVE_CRYPTO_ASSETS_PATH),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(retrieveCryptoSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCryptoFailure(err));
  }
}

// Retrieve crypto asset details by id
export function* retrieveCryptoAssetAPI() {
  while (true) {
    const action = yield take(`${retrieveCryptoAsset}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${RETRIEVE_CRYPTO_ASSET_PATH}${action.payload.id}`),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(retrieveCryptoAssetSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCryptoFailure(err));
  }
}

// Retrieve service providers
export function* retrieveServiceProvidersAPI() {
  while (true) {
    yield take(`${retrieveServiceProviders}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${RETRIEVE_SERVICE_PROVIDERS_PATH}`),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(retrieveServiceProvidersSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveServiceProvidersFailure(err));
  }
}

// Create new crypto asset
export function* createCryptoAPI() {
  while (true) {
    const action = yield take(`${createCrypto}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_CRYPTO_ASSET_PATH),
      action.payload.data,
      "POST"
    );

    yield call(action.payload.callback, payload || err);

    // If success, retrieve all crypto assets
    if (payload && !err) {
      yield put(createCryptoSuccess(payload));
      yield put(retrieveCrypto({}));
      continue;
    }
    endSession(err);
    yield put(retrieveCryptoFailure(err));
  }
}

// Update crypto asset by id
export function* updateCryptoAPI() {
  while (true) {
    const action = yield take(`${updateCrypto}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_CRYPTO_ASSET_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(updateCryptoSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCryptoFailure(err));
  }
}

// Delete crypto asset by id
export function* deleteCryptoAPI() {
  while (true) {
    const action = yield take(`${deleteCryptoAssets}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(DELETE_CRYPTO_ASSETS_PATH),
      action.payload.data,
      "POST"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(deleteCryptoSuccess(payload));

      if (action.payload.path) {
        yield put(searchCrypto({ path: action.payload.path }));
      } else {
        yield put(retrieveCrypto({}));
      }
      continue;
    }
    endSession(err);
    yield put(deleteCryptoSuccess(err));
  }
}

// Search crypto asset by criteria
export function* searchCryptoAPI() {
  while (true) {
    const action = yield take(`${searchCrypto}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${SEARCH_CRYPTO_ASSET_PATH}${action.payload.path}`),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(searchCryptoSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCryptoFailure(err));
  }
}

// Create new crypto asset by bulk upload
export function* fileUploadInitAPI() {
  while (true) {
    const action = yield take(`${fileUploadInit}`);

    // Retrieve upload URL
    const { payload } = yield call(
      ApiInvoke,
      commonEndpoint(FILE_UPLOAD_INIT_PATH),
      {
        action: UPLOAD_ACTION,
        type: CRYPTO_BINARY_UPLOAD,
        file: action.payload.data.name,
      },
      "POST"
    );

    if (payload.status === 200) {
      const uploadURL = payload.data;
      // Upload file to generated URL
      const { res } = yield call(fileUpload, {
        url: uploadURL,
        type: "PUT",
        data: action.payload.data,
      });

      if (res.ok) {
        yield call(action.payload.callback, action.payload);
      }
    }
  }
}

// Create crypto assets by bulk upload
export function* cryptoBulkUploadAPI() {
  while (true) {
    const action = yield take(`${cryptoBulkUpload}`);
    // const fileNameIndex = action.payload.data.name.lastIndexOf(".");
    // const csvFileName: string = action.payload.data.name.slice(
    //   0,
    //   fileNameIndex
    // );
    // const spid = csvFileName.slice(0, csvFileName.indexOf("_"));
    // const fileName = `${csvFileName}_${new Date().getTime()}${spid}.zip`;
    // const fileName = `${new Date().getTime()}${String(Math.floor(Math.random()*1000)).padStart(3, "0")}.zip`;

    // Define file name for bulk upload
    const fileName = action.payload.data.name;

    // Retrieve upload URL
    const { payload, err } = yield call(
      ApiInvoke,
      commonEndpoint(FILE_UPLOAD_INIT_PATH),
      {
        action: UPLOAD_ACTION,
        type: CRYPTO_UPLOAD,
        file: fileName,
      },
      "POST"
    );

    if (payload.status === 200) {
      const uploadURL = payload.data;
      // Upload file to generated URL
      const { res } = yield call(fileUpload, {
        url: uploadURL,
        type: "PUT",
        data: action.payload.data,
        fileName: fileName,
        contentType: "multipart/form-data",
      });

      if (res.ok) {
        // If upload success, call crypto bulk create api to create crypto assets
        const { payload, err } = yield call(
          ApiInvoke,
          backendEndpoint(`${CREATE_CRYPTO_BULK_PATH}${fileName}`),
          "",
          "GET"
        );

        // fetch(backendEndpoint("crypto/bulk/upload/" + fileName)).then(
        //   async (response) => {
        //     console.log("file upload", response);
        //   }
        // );
        console.log("bulk::: payload::", payload);

        if (err || !payload || payload.status !== 200) {
          res["error"] = "Invalid Zip Content Data";
          res["message"] = "Invalid Zip Content Data";
          res["data"] = {};

          // Handle error when bulk create failed
          yield put(cryptoBulkUploadFailure({}));
          yield call(action.payload.errorCallback, res);
        } else if (payload.status === 200) {
          // Display success message when bulk create success
          // Reload crypto assets table
          res["message"] = "File uploaded successfully!";
          yield put(cryptoBulkUploadSuccess({}));
          yield call(action.payload.callback, res);
          yield put(retrieveCrypto({}));
        }
      }
      endSession(err);
    } else {
      // Handle error when file upload failed
      yield put(cryptoBulkUploadFailure({}));
      yield call(action.payload.errorCallback, {
        error: "Error while file upload",
        message: "Error while file upload",
        data: {},
      });
    }
  }
}

// Function to get crypto sample CSV download URL
export function* getCryptoUploadSampleCSVAPI() {
  while (true) {
    const action = yield take(`${getCryptoUploadSampleCSV}`);

    // Retrieve download URL
    const { payload, err } = yield call(
      ApiInvoke,
      commonEndpoint(FILE_UPLOAD_INIT_PATH),
      {
        action: DOWNLOAD_ACTION,
        type: "CRYPTO_UPLOAD",
        file: "sampleFile.csv",
      },
      "POST"
    );

    if (payload.status === 200) {
      yield call(action.payload.callback, payload);
    }

    if (payload && !err) {
      yield put(getCryptoUploadSampleCSVSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getCryptoUploadSampleCSVFailure(err));
  }
}

// Function to get crypto statistics
export function* getCryptoStatsAPI() {
  while (true) {
    yield take(`${getCryptoStats}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(GET_CRYPTO_STATS_PATH),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(getCryptoStatsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getCryptoStatsFailure(err));
  }
}

// Function to get crypto bibary download url
export function* downloadCryptoAssetAPI() {
  while (true) {
    const action = yield take(`${downloadCryptoAsset}`);

    const { payload, err } = yield call(
      ApiInvoke,
      commonEndpoint(FILE_UPLOAD_INIT_PATH),
      {
        action: DOWNLOAD_ACTION,
        type: CRYPTO_BINARY_UPLOAD,
        file: action.payload.file,
      },
      "POST"
    );

    if (payload) {
      yield call(action.payload.callback, payload);
    }
    endSession(err);
  }
}

// Function to get crypto audit logs for a crypto asset
export function* getCryptoAuditLogsAPI() {
  while (true) {
    const action = yield take(`${getAuditLogs}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${AUDIT_LOGS_PATH}${action.payload.id}${GET_AUDIT_LOGS_PATH}`),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(getAuditLogsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(getAuditLogsFailure(err));
  }
}

// Function to get crypto signed binary download url
export function* downloadSignedBinaryAPI() {
  while (true) {
    const action = yield take(`${downloadSignedBinary}`);

    const { payload, err } = yield call(
      ApiInvoke,
      commonEndpoint(FILE_UPLOAD_INIT_PATH),
      {
        action: DOWNLOAD_ACTION,
        type: CRYPTO_BINARY_UPLOAD_SIGNED,
        file: action.payload.file,
      },
      "POST"
    );

    if (payload) {
      yield call(action.payload.callback, payload);
    }
    endSession(err);
  }
}

// Function to search crypto asset for given criteria
export function* eligibilitySearchCryptoAPI() {
  while (true) {
    const action = yield take(`${eligibilitySearchCrypto}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${ELIGIBILITY_SEARCH_CRYPTO_ASSET_PATH}`),
      action.payload.data,
      "POST"
    );

    if (payload && !err) {
      yield put(eligibilitySearchCryptoSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveCryptoFailure(err));
  }
}
