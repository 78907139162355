import { fork } from "redux-saga/effects";
import * as auth from "./auth";
import * as device from "./device";
import * as category from "./category";
import * as crypto from "./crypto";
import * as eligibility from "./eligibility";
import * as user from "./user";
import * as userGroup from "./userGroup";
import * as permission from "./permission";
import * as certificate from "./certificate";
import * as themeSwitch from "./themeSwitcher";
import * as settings from "./settings";
// import { formatListToParts } from '@formatjs/intl/src/list';

export default function* rootSaga() {
  yield fork(settings.retrieveSettingsAPI);
  yield fork(user.createUserApi);
  yield fork(user.retrieveUsersAPI);
  yield fork(user.deleteUserAPI);
  yield fork(user.searchUserAPI);
  yield fork(user.retrieveMfa);
  yield fork(permission.createPermissionApi);
  yield fork(permission.updatePermissionAPI);
  yield fork(permission.retrievePermissionsAPI);
  yield fork(permission.retrieveUserPermissionsAPI);
  yield fork(permission.deletePermissionAPI);
  yield fork(userGroup.retrieveUserGroupsAPI);
  yield fork(userGroup.createUserGroupApi);
  yield fork(userGroup.updateUsergroupAPI);
  yield fork(userGroup.deleteUserGroupAPI);
  yield fork(auth.handleFetchLoginState);
  yield fork(auth.handleLogin);
  yield fork(auth.handleLogout);
  yield fork(auth.handleApi);
  yield fork(auth.handleOTPLogin);
  yield fork(user.updateUserAPI);
  yield fork(device.retrieveDevicesAPI);
  yield fork(device.retrieveDeviceAPI);
  yield fork(device.createDeviceAPI);
  yield fork(device.deleteDeviceAPI);
  yield fork(device.updateDeviceAPI);
  yield fork(device.searchDeviceAPI);
  yield fork(device.createDeviceBulkAPI);
  yield fork(device.deviceBulkUploadInitAPI);
  yield fork(device.deviceBulkUploadStatusAPI);
  yield fork(device.getDeviceStatsAPI);
  yield fork(device.getDeviceTimelineAPI);
  yield fork(device.getDeviceUploadSampleCSVAPI);
  yield fork(device.getTEEPropertiesAPI);
  yield fork(device.getDeviceTransactionLogsAPI);
  yield fork(device.eligibilitySearchDeviceAPI);
  yield fork(category.createCategoryAPI);
  yield fork(category.retrieveCategoriesAPI);
  yield fork(category.updateCategoryAPI);
  yield fork(category.deleteCategoryAPI);
  yield fork(crypto.createCryptoAPI);
  yield fork(crypto.retrieveCryptoAssetAPI);
  yield fork(crypto.retrieveCryptoAPI);
  yield fork(crypto.retrieveServiceProvidersAPI);
  yield fork(crypto.updateCryptoAPI);
  yield fork(crypto.deleteCryptoAPI);
  yield fork(crypto.searchCryptoAPI);
  yield fork(crypto.fileUploadInitAPI);
  yield fork(crypto.getCryptoStatsAPI);
  yield fork(crypto.downloadCryptoAssetAPI);
  yield fork(crypto.getCryptoAuditLogsAPI);
  yield fork(crypto.cryptoBulkUploadAPI);
  yield fork(crypto.getCryptoUploadSampleCSVAPI);
  yield fork(crypto.downloadSignedBinaryAPI);
  yield fork(crypto.eligibilitySearchCryptoAPI);
  yield fork(eligibility.createEligibilityRuleAPI);
  yield fork(eligibility.retrieveEligibilityRuleAPI);
  yield fork(eligibility.retrieveEligibilityRulesAPI);
  yield fork(eligibility.updateEligibilityRuleAPI);
  yield fork(eligibility.deleteEligibilityRulesAPI);
  yield fork(eligibility.searchEligibilityAPI);
  yield fork(eligibility.getEligibilityStatsAPI);
  yield fork(eligibility.changeEligibilityStatusAPI);
  yield fork(certificate.createCertificateAPI);
  yield fork(certificate.retrieveCertificatesAPI);
  yield fork(certificate.retrieveCertificateAPI);
  yield fork(certificate.searchCertificateAPI);
  yield fork(themeSwitch.switchThemeFunc);
}
