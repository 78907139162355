import React from "react";

type Props = {
  type: any;
  className?: string;
};

export const Icon = (props: Props) => {
  const { type, className } = props;
  return <>{React.createElement(type, { className: className })}</>;
};

export default Icon;
