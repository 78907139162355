import { take, call, put } from "redux-saga/effects";
import {
  retriveUserGroups,
  retriveUserGroupsSuccess,
  retriveUserGroupsFailiure,
  createUserGroup,
  createUserGroupSuccess,
  updateUserGroup,
  updateUserGroupSuccess,
  deleteUserGroup,
  deleteUserGroupSuccess,
} from "../actions/userGroup";
import {
  RETRIEVE_USEGROUPS_PATH,
  CREATE_USER_GROUP_PATH,
  UPDATE_USER_GROUP_PATH,
  DELETE_USER_GROUP_PATH,
} from "../constants/UserGroups";
import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

// Function to get backend endpoint for user groups
const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_USERS_BACKEND_DEV}/${path}`
    : process.env.REACT_APP_ENV === "staging"
    ? `${process.env.REACT_APP_USERS_BACKEND_STAGING}/${path}`
    : `${process.env.REACT_APP_USERS_BACKEND_QA}/${path}`;

// Function to create user group
export function* createUserGroupApi() {
  while (true) {
    const action = yield take(`${createUserGroup}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_USER_GROUP_PATH),
      action.payload.data,
      "POST"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(createUserGroupSuccess(payload));
      yield put(retriveUserGroups({}));
      continue;
    }
    endSession(err);
  }
}

// Function to retrieve all user groups
export function* retrieveUserGroupsAPI() {
  while (true) {
    yield take(`${retriveUserGroups}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(RETRIEVE_USEGROUPS_PATH),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(retriveUserGroupsSuccess(payload));

      continue;
    }
    endSession(err);
    yield put(retriveUserGroupsFailiure(err));
  }
}

// Function to update user group by id
export function* updateUsergroupAPI() {
  while (true) {
    const action = yield take(`${updateUserGroup}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${UPDATE_USER_GROUP_PATH}${action.payload.id}`),
      action.payload.data,
      "PUT"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      // Reload user groups table
      yield put(updateUserGroupSuccess(payload));
      yield put(retriveUserGroups({}));
      continue;
    }
    endSession(err);
    yield put(retriveUserGroupsFailiure(err));
  }
}

// Function to delete user group
export function* deleteUserGroupAPI() {
  while (true) {
    const action = yield take(`${deleteUserGroup}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(DELETE_USER_GROUP_PATH),
      action.payload.data,
      "POST"
    );

    yield call(action.payload.callback, payload || err);

    if (payload && !err) {
      yield put(deleteUserGroupSuccess(payload));
      yield put(retriveUserGroups({}));
      continue;
    }
    endSession(err);
    yield put(retriveUserGroupsFailiure(err));
  }
}
