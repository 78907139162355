// import { TPermission, UserPermissionState } from 'types/permission';
import {
  RETRIEVE_PERMISSIONS,
  RETRIEVE_PERMISSIONS_SUCCESS,
  RETRIEVE_PERMISSIONS_FAILIURE,
  RETRIEVE_USER_PERMISSIONS,
  RETRIEVE_USER_PERMISSIONS_SUCCESS,
  RETRIEVE_USER_PERMISSIONS_FAILIURE,
  CREATE_PERMISSIONS,
  CREATE_PERMISSIONS_SUCCESS,
  // CREATE_PERMISSIONS_FAILIURE,
  UPDATE_PERMISSIONS,
  UPDATE_PERMISSIONS_SUCCESS,
  // UPDATE_PERMISSIONS_FAILIURE,
  DELETE_PERMISSIONS,
  DELETE_PERMISSIONS_SUCCESS,
} from "../constants/Permissions";

const initialState = {
  permissionsLoading: false, // Flag indicating if permissions are being loaded
  error: false, // Flag indicating if an error occurred
  total: 0, // Total number of permissions
  message: "", // Message related to permissions
  permissions: [], // Array of permissions
  user_permissions: [], // Array of user permissions
};

function userPermissionReducer(state = initialState, action) {
  const { type, payload } = action;
  console.debug(action);

  switch (type) {
    case RETRIEVE_PERMISSIONS:
      // Retrieve permissions action
      return { ...state, permissionsLoading: true };

    case RETRIEVE_PERMISSIONS_SUCCESS:
      // Successfully retrieved permissions action
      return {
        ...state,
        permissionsLoading: false,
        permissions: payload.data.permissions ?? [],
      };

    case RETRIEVE_PERMISSIONS_FAILIURE:
      // Failed to retrieve permissions action
      return {
        ...state,
        permissionsLoading: false,
        error: true,
        message: payload.data.message || "",
      };

    case RETRIEVE_USER_PERMISSIONS:
      // Retrieve user permissions action
      return { ...state, permissionsLoading: true };

    case RETRIEVE_USER_PERMISSIONS_SUCCESS:
      // Successfully retrieved user permissions action
      return {
        ...state,
        permissionsLoading: false,
        user_permissions: payload.data.permissions ?? [],
      };

    case RETRIEVE_USER_PERMISSIONS_FAILIURE:
      // Failed to retrieve user permissions action
      return {
        ...state,
        permissionsLoading: false,
        error: true,
        message: "Insufficient Permissions",
      };

    case CREATE_PERMISSIONS:
      // Create permissions action
      return {
        ...state,
        permissionsLoading: true,
        message: payload.data.message,
      };

    case CREATE_PERMISSIONS_SUCCESS:
      // Successfully created permissions action
      return {
        ...state,
        permissionsLoading: false,
        message: payload.data.message,
      };

    case UPDATE_PERMISSIONS:
      // Update permissions action
      return {
        ...state,
        permissionsLoading: true,
        permissions: state.permissions.map((permission: any) => {
          if (permission.id === payload.data.id) {
            console.log("EEEEEEEEEEEEE ", payload);
            return {
              ...permission,
              ...payload.data,
            };
          } else {
            return permission;
          }
        }),
      };

    case UPDATE_PERMISSIONS_SUCCESS:
      // Successfully updated permissions action
      return {
        ...state,
        permissionsLoading: false,
        message: payload.data.message,
      };

    case DELETE_PERMISSIONS:
      // Delete permissions action
      return {
        ...state,
        permissionsLoading: true,
        rules: state.permissions.map((permission: any, index) => {
          console.log(permission.id, payload.id);
          if (permission.id === payload.id) {
            return {
              ...state.permissions.splice(index, 1),
            };
          } else {
            return permission;
          }
        }),
      };

    case DELETE_PERMISSIONS_SUCCESS:
      // Successfully deleted permissions action
      return {
        ...state,
        permissionsLoading: false,
        permissions: payload.data.users,
      };

    default:
      return state;
  }
}

export default userPermissionReducer;
