import { take, call, put } from "redux-saga/effects";
import {
  retriveCertificates,
  retriveCertificatesSuccess,
  retriveCertificatesFailiure,
  retriveCertificate,
  retriveCertificateSuccess,
  retriveCertificateFailiure,
  createCertificate,
  createCertificateSuccess,
  searchCertificate,
  searchCertificateSuccess,
} from "../actions/Certificate";
import {
  FIND_ALL_CERTIFICATES_PATH,
  FIND_ONE_CERTIFICATE_PATH,
  CREATE_CERTIFICATE_PATH,
  SEARCH_CERTIFICATE_PATH,
} from "../constants/Certificate";
import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

// Function to get backend endpoint based on environment
const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_CERTIFICATE_CONTAINER_DEV}/${path}`
    : process.env.REACT_APP_ENV === "staging"
    ? `${process.env.REACT_APP_CERTIFICATE_CONTAINER_STAGING}/${path}`
    : `${process.env.REACT_APP_CERTIFICATE_CONTAINER_QA}/${path}`;

// Enroll new certificate API
export function* createCertificateAPI() {
  while (true) {
    const action = yield take(`${createCertificate}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(CREATE_CERTIFICATE_PATH),
      action.payload.data,
      "POST"
    );

    if (payload && !err) {
      yield call(action.payload.callback, payload);
      yield put(createCertificateSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retriveCertificatesFailiure(err));
  }
}

// Retrieve all certificates API
export function* retrieveCertificatesAPI() {
  while (true) {
    yield take(`${retriveCertificates}`);
    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(FIND_ALL_CERTIFICATES_PATH),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(retriveCertificatesSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retriveCertificatesFailiure(err));
  }
}

// Retrieve certificate API by id
export function* retrieveCertificateAPI() {
  while (true) {
    const action = yield take(`${retriveCertificate}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${FIND_ONE_CERTIFICATE_PATH}${action.payload.id}`),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(retriveCertificateSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retriveCertificateFailiure(err));
  }
}

// Search certificate API
export function* searchCertificateAPI() {
  while (true) {
    const action = yield take(`${searchCertificate}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(`${SEARCH_CERTIFICATE_PATH}${action.payload.path}`),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(searchCertificateSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retriveCertificatesFailiure(err));
  }
}
