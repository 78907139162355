export const CREATE_CRYPTO_ASSET = "CREATE_CRYPTO_ASSET";
export const CREATE_CRYPTO_ASSET_SUCCESS = "CREATE_CRYPTO_ASSET_SUCCESS";
export const UPDATE_CRYPTO_ASSET = "UPDATE_CRYPTO_ASSET";
export const UPDATE_CRYPTO_ASSET_SUCCESS = "UPDATE_CRYPTO_ASSET_SUCCESS";
export const RETRIEVE_CRYPTO_ASSETS_FAIL = "RETRIEVE_CRYPTO_ASSETS_FAIL";
export const DELETE_CRYPTO_ASSET = "DELETE_CRYPTO_ASSET";
export const DELETE_CRYPTO_ASSETS = "DELETE_CRYPTO_ASSETS";
export const DELETE_CRYPTO_ASSET_SUCCESS = "DELETE_CRYPTO_ASSET_SUCCESS";
export const SEARCH_CRYPTO_ASSET = "SEARCH_CRYPTO_ASSET";
export const SEARCH_CRYPTO_ASSET_SUCCESS = "SEARCH_CRYPTO_ASSET_SUCCESS";
export const CRYPTO_BULK_UPLOAD = "CRYPTO_BULK_UPLOAD";
export const CRYPTO_BULK_UPLOAD_SUCCESS = "CRYPTO_BULK_UPLOAD_SUCCESS";
export const CRYPTO_BULK_UPLOAD_FAILURE = "CRYPTO_BULK_UPLOAD_FAILURE";
export const CRYPTO_UPLOAD = "CRYPTO_UPLOAD";

export const RETRIEVE_CRYPTO_ASSET = "RETRIEVE_CRYPTO_ASSET";
export const RETRIEVE_CRYPTO_ASSET_SUCCESS = "RETRIEVE_CRYPTO_ASSET_SUCCESS";
export const RETRIEVE_CRYPTO_ASSETS = "RETRIEVE_CRYPTO_ASSETS";
export const RETRIEVE_CRYPTO_ASSETS_SUCCESS = "RETRIEVE_CRYPTO_ASSETS_SUCCESS";

export const RETRIEVE_SERVICE_PROVIDERS = "RETRIEVE_SERVICE_PROVIDERS";
export const RETRIEVE_SERVICE_PROVIDERS_SUCCESS = "RETRIEVE_SERVICE_PROVIDERS_SUCCESS";
export const RETRIEVE_SERVICE_PROVIDERS_FAILURE = "RETRIEVE_SERVICE_PROVIDERS_FAILURE";

export const GET_CRYPTO_UPLOAD_SAMPLE_CSV = "GET_CRYPTO_UPLOAD_SAMPLE_CSV";
export const GET_CRYPTO_UPLOAD_SAMPLE_CSV_SUCCESS = "GET_CRYPTO_UPLOAD_SAMPLE_CSV_SUCCESS";
export const GET_CRYPTO_UPLOAD_SAMPLE_CSV_FAILURE = "GET_CRYPTO_UPLOAD_SAMPLE_CSV_FAILURE";

export const FILE_UPLOAD_INIT = "FILE_UPLOAD_INIT";
export const CRYPTO_BINARY_UPLOAD = "CRYPTO_BINARY_UPLOAD";
export const DOWNLOAD_CRYPTO_ASSET = "DOWNLOAD_CRYPTO_ASSET";

export const GET_CRYPTO_STATS = "GET_CRYPTO_STATS";
export const GET_CRYPTO_STATS_SUCCESS = "GET_CRYPTO_STATS_SUCCESS";
export const GET_CRYPTO_STATS_FAILURE = "GET_CRYPTO_STATS_FAILURE";

export const GET_AUDIT_LOGS = "GET_AUDIT_LOGS";
export const GET_AUDIT_LOGS_SUCCESS = "GET_AUDIT_LOGS_SUCCESS";
export const GET_AUDIT_LOGS_FAILURE = "GET_AUDIT_LOGS_FAILURE";

export const ELIGIBILITY_SEARCH_CRYPTO_ASSET = "ELIGIBILITY_SEARCH_CRYPTO_ASSET";
export const ELIGIBILITY_SEARCH_CRYPTO_ASSET_SUCCESS = "ELIGIBILITY_SEARCH_CRYPTO_ASSET_SUCCESS";

export const CRYPTO_BINARY_UPLOAD_SIGNED = "CRYPTO_BINARY_UPLOAD_SIGNED";

export const RETRIEVE_CRYPTO_ASSET_PATH = "crypto/";
export const RETRIEVE_CRYPTO_ASSETS_PATH = "crypto/all";
export const RETRIEVE_SERVICE_PROVIDERS_PATH = "crypto/serviceprovider/allProviders";
export const CREATE_CRYPTO_ASSET_PATH = "crypto";
export const UPDATE_CRYPTO_ASSET_PATH = "crypto/";
export const DELETE_CRYPTO_ASSET_PATH = "crypto/";
export const DELETE_CRYPTO_ASSETS_PATH = "crypto/bulk/delete";
export const SEARCH_CRYPTO_ASSET_PATH = "crypto/advance/search";
export const FILE_UPLOAD_INIT_PATH = "common/signedurl";
export const GET_CRYPTO_STATS_PATH = "crypto/statistic/summary";
export const CREATE_CRYPTO_BULK_PATH = "crypto/bulk/upload/";

export const AUDIT_LOGS_PATH = "crypto/";
export const GET_AUDIT_LOGS_PATH = "/auditTrail";
export const ELIGIBILITY_SEARCH_CRYPTO_ASSET_PATH = "crypto/crypto/search";
