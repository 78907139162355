export const CREATE_DEVICE = "CREATE_DEVICE";
export const CREATE_DEVICE_SUCCESS = "CREATE_DEVICE_SUCCESS";
export const DELETE_DEVICE = "DELETE_DEVICE";
export const DELETE_DEVICE_SUCCESS = "DELETE_DEVICE_SUCCESS";
export const RETRIEVE_DEVICE = "RETRIEVE_DEVICE";
export const RETRIEVE_DEVICE_SUCCESS = "RETRIEVE_DEVICE_SUCCESS";
export const RETRIEVE_DEVICES = "RETRIEVE_DEVICES";
export const DELETE_ALL_DEVICES = "DELETE_ALL_DEVICES";
export const RETRIEVE_DEVICES_SUCCESS = "RETRIEVE_DEVICES_SUCCESS";
export const RETRIEVE_DEVICES_FAIL = "RETRIEVE_DEVICES_FAIL";
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS";
export const SEARCH_DEVICE = "SEARCH_DEVICE";
export const SEARCH_DEVICE_SUCCESS = "SEARCH_DEVICE_SUCCESS";

export const CREATE_DEVICE_BULK = "CREATE_DEVICE_BULK";
export const DEVICE_BULK_UPLOAD = "DEVICE_BULK_UPLOAD";
export const DEVICE_BULK_UPLOAD_STATUS = "DEVICE_BULK_UPLOAD_STATUS";
export const DEVICE_BULK_UPLOAD_STATUS_SUCCESS = "DEVICE_BULK_UPLOAD_STATUS_SUCCESS";
export const DEVICE_UPLOAD = "DEVICE_UPLOAD";
export const GET_DEVICE_STATS = "GET_DEVICE_STATS";
export const GET_DEVICE_STATS_SUCCESS = "GET_DEVICE_STATS_SUCCESS";
export const GET_DEVICE_STATS_FAILURE = "GET_DEVICE_STATS_FAILURE";

export const GET_DEVICE_UPLOAD_SAMPLE_CSV = "GET_DEVICE_UPLOAD_SAMPLE_CSV";
export const GET_DEVICE_UPLOAD_SAMPLE_CSV_SUCCESS = "GET_DEVICE_UPLOAD_SAMPLE_CSV_SUCCESS";
export const GET_DEVICE_UPLOAD_SAMPLE_CSV_FAILURE = "GET_DEVICE_UPLOAD_SAMPLE_CSV_FAILURE";
export const ACTION_SAMPLE_CSV_NAME = "action_sample-1.csv";
export const CREATE_SAMPLE_CSV_NAME = "create_sample.csv";

export const GET_DEVICE_TIMELINE = "GET_DEVICE_TIMELINE";
export const GET_DEVICE_TIMELINE_SUCCESS = "GET_DEVICE_TIMELINE_SUCCESS";
export const GET_DEVICE_TIMELINE_FAILURE = "GET_DEVICE_TIMELINE_FAILURE";

export const GET_TEE_PROPERTIES = "GET_TEE_PROPERTIES";
export const GET_TEE_PROPERTIES_SUCCESS = "GET_TEE_PROPERTIES_SUCCESS";
export const GET_TEE_PROPERTIES_FAILURE = "GET_TEE_PROPERTIES_FAILURE";

export const GET_TRANSACTION_LOGS = "GET_TRANSACTION_LOGS";
export const GET_TRANSACTION_LOGS_SUCCESS = "GET_TRANSACTION_LOGS_SUCCESS";
export const GET_TRANSACTION_LOGS_FAILURE = "GET_TRANSACTION_LOGS_FAILURE";

export const ELIGIBILITY_SEARCH_DEVICE = "ELIGIBILITY_SEARCH_DEVICE";
export const ELIGIBILITY_SEARCH_DEVICE_SUCCESS = "ELIGIBILITY_SEARCH_DEVICE_SUCCESS";

// Device API Paths
export const CREATE_DEVICE_PATH = "device";
export const RETRIEVE_DEVICES_PATH = "device/all";
export const RETRIEVE_DEVICE_PATH = "device/";
export const DELETE_DEVICE_PATH = "device/bulk/delete";
export const SEARCH_DEVICE_PATH = "device/advance/search";
export const UPDATE_DEVICE_PATH = "device/";
export const CREATE_DEVICE_BULK_PATH = "device/bulk/upload/";
export const DEVICE_BULK_UPLOAD_STATUS_PATH = "device/device/upload/logs";
export const GET_DEVICE_STATS_PATH = "device/statistic/summary";
export const GET_DEVICE_TIMELINE_PATH = "device/device/workflow/";

export const TEE_PROPERTIES_PATH = "device/";
export const TEE_PROPERTY = "/teeProperty";

export const TRANSACTION_PATH = "v1/provision/device/";
export const TRANSACTION_PROPERTY = "/transactions";

export const ELIGIBILITY_SEARCH_DEVICE_PATH = "device/device/search";

// "deploy": "aws s3 sync ./build s3://tau.axcer.io --profile ta & aws cloudfront create-invalidation --distribution-id E6KOMUPWHA77H --paths \"/*\" --profile ta",
