import { SWITCH_THEME, SWITCH_THEME_SUCCESS } from "../constants/ThemeSwitcher";

const initialState = {
  loading: false,
  currentTheme: "light", // Default theme set to "light"
};

function themeSwitcherReducer(state = initialState, action) {
  const { type, payload } = action;

  console.log(type, payload); // Log the type and payload for debugging purposes

  switch (type) {
    case SWITCH_THEME:
      // Action to switch theme initiated
      return {
        ...state,
        loading: true, // Set loading state to true
      };

    case SWITCH_THEME_SUCCESS:
      // Theme switch successful
      return {
        ...state,
        loading: false, // Set loading state to false
        currentTheme: payload.theme, // Update the current theme with the new theme from the payload
      };

    default:
      return state;
  }
}

export default themeSwitcherReducer;
