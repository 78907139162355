import { UserGroupState } from "types/user";
import {
  RETRIEVE_USEGROUPS,
  RETRIEVE_USEGROUPS_SUCCESS,
  RETRIEVE_USEGROUPS_FAILIURE,
  CREATE_USER_GROUP,
  CREATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP,
  UPDATE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP,
  // DELETE_USER_GROUP_SUCCESS
} from "../constants/UserGroups";

const initialState: UserGroupState = {
  loading: false,
  error: false,
  total: 0,
  message: "",
  userGroup: [],
};

function userGroupReducer(state = initialState, action) {
  const { type, payload } = action;
  console.debug(action); // Log the action for debugging purposes

  switch (type) {
    case CREATE_USER_GROUP:
      // Creating a new user group
      return {
        ...state,
        loading: true, // Set loading state to true
        message: payload.data.message, // Update the message with the message from the payload
      };
    case CREATE_USER_GROUP_SUCCESS:
      // User group creation successful
      return {
        ...state,
        loading: false, // Set loading state to false
        message: payload.data.message, // Update the message with the message from the payload
      };

    case RETRIEVE_USEGROUPS:
      // Retrieving user groups
      return { ...state, loading: true }; // Set loading state to true

    case RETRIEVE_USEGROUPS_SUCCESS:
      // User groups retrieval successful
      return { ...state, loading: false, groups: payload.data.groups }; // Update the groups with the groups data from the payload

    case RETRIEVE_USEGROUPS_FAILIURE:
      // User groups retrieval failed
      return {
        ...state,
        loading: false, // Set loading state to false
        error: true, // Set error state to true
        message: payload.data.message || "", // Update the message with the message from the payload, or empty string if not available
      };

    case UPDATE_USER_GROUP:
      // Updating a user group
      return {
        ...state,
        loading: true, // Set loading state to true
        groups: state.userGroup.map((groups: any) => {
          if (groups.id === payload.data.id) {
            return {
              ...groups,
              ...payload.data,
            };
          } else {
            return groups;
          }
        }),
      };

    case UPDATE_USER_GROUP_SUCCESS:
      // User group update successful
      return { ...state, loading: false, message: payload.message }; // Set loading state to false, update the message with the message from the payload

    case DELETE_USER_GROUP:
      // Deleting a user group
      return { ...state, loading: true }; // Set loading state to true

    default:
      return state;
  }
}

export default userGroupReducer;
