import {
  CREATE_CERTIFICATE,
  CREATE_CERTIFICATE_SUCCESS,
  RETRIEVE_CERTIFICATES,
  RETRIEVE_CERTIFICATES_SUCCESS,
  RETRIEVE_CERTIFICATES_FAILIURE,
  RETRIEVE_CERTIFICATE,
  RETRIEVE_CERTIFICATE_SUCCESS,
  RETRIEVE_CERTIFICATE_FAILIURE,
  RETRIEVE_CERTIFICATE_STATUS,
  RETRIEVE_CERTIFICATE_STATUS_SUCCESS,
  RETRIEVE_CERTIFICATE_STATUS_FAILIURE,
  SEARCH_CERTIFICATE,
  SEARCH_CERTIFICATE_SUCCESS,
} from "../constants/Certificate";

const initialState = {
  // Initial state for certificates
  loading: false, // Flag indicating if certificates are being loaded
  error: false, // Flag indicating if an error occurred
  total: 0, // Total number of certificates
  message: "", // Message related to certificate operations
  certificates: [], // Array of certificate objects
  certificate: {}, // Single certificate object
  certificateStatus: {}, // Certificate status object
};

function certificateReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CERTIFICATE:
      // Create certificate action
      return { ...state, loading: true };

    case CREATE_CERTIFICATE_SUCCESS:
      // Create certificate success action
      return {
        ...state,
        loading: false,
        certificates: [...state.certificates, payload.data],
        message: payload.message,
      };

    case RETRIEVE_CERTIFICATES:
      // Retrieve certificates action
      return { ...state, loading: true };

    case RETRIEVE_CERTIFICATES_SUCCESS:
      // Successfully retrieved certificates action
      return {
        ...state,
        loading: false,
        certificates: payload.data.certificates,
      };

    case RETRIEVE_CERTIFICATES_FAILIURE:
      // Failed to retrieve certificates action
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.message || "",
      };

    case RETRIEVE_CERTIFICATE:
      // Retrieve certificate action
      return { ...state, loading: true };

    case RETRIEVE_CERTIFICATE_SUCCESS:
      // Successfully retrieved certificate action
      return {
        ...state,
        loading: false,
        certificate: payload.data.certificate,
      };

    case RETRIEVE_CERTIFICATE_FAILIURE:
      // Failed to retrieve certificate action
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.message || "",
      };

    case RETRIEVE_CERTIFICATE_STATUS:
      // Retrieve certificate status action
      return { ...state, loading: true };

    case RETRIEVE_CERTIFICATE_STATUS_SUCCESS:
      // Successfully retrieved certificate status action
      return { ...state, loading: false, certificateStatus: payload.data };

    case RETRIEVE_CERTIFICATE_STATUS_FAILIURE:
      // Failed to retrieve certificate status action
      return {
        ...state,
        loading: false,
        error: true,
        message: payload.message || "",
      };

    case SEARCH_CERTIFICATE:
      // Search certificate action
      return { ...state, loading: true };

    case SEARCH_CERTIFICATE_SUCCESS:
      // Successfully searched certificates action
      return {
        ...state,
        loading: false,
        certificates: payload.data.certificates,
      };

    default:
      return state;
  }
}

export default certificateReducer;
