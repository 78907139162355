export const logOut = () => {
  localStorage.clear();
};

export const getAccessToken = (): string => {
  try {
    const token = localStorage.getItem("tauJWT");
    if (token) {
      return token;
    } else {
      return "";
    }
  } catch (exception) {
    return "";
  }
};

export const getCognitoAccessToken = (): string => {
  try {
    const token =
      "Bearer " +
      localStorage.getItem(
        `CognitoIdentityServiceProvider.${
          process.env.REACT_APP_ENV === "dev"
            ? process.env.REACT_APP_CLIENT_ID
            : process.env.REACT_APP_CLIENT_ID_QA
        }.${localStorage.getItem(
          `CognitoIdentityServiceProvider.${
            process.env.REACT_APP_ENV === "dev"
              ? process.env.REACT_APP_CLIENT_ID
              : process.env.REACT_APP_CLIENT_ID_QA
          }.LastAuthUser`
        )}.accessToken`
      );
    if (token) {
      return token;
    } else {
      return "";
    }
  } catch (exception) {
    return "";
  }
};
