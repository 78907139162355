import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  DELETE_CATEGORY_SUCCESS,
  RETRIEVE_CATEGORIES,
  RETRIEVE_CATEGORIES_FAIL,
  RETRIEVE_CATEGORIES_SUCCESS,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
} from "../constants/Category";

import { createAction } from "redux-act";

// Category
export const createCategory = createAction<any | undefined>(CREATE_CATEGORY); // Action creator for creating a category
export const createCategorySuccess = createAction<any | undefined>(CREATE_CATEGORY_SUCCESS); // Action creator for successful category creation
export const updateCategory = createAction<any | undefined>(UPDATE_CATEGORY); // Action creator for updating a category
export const updateCategorySuccess = createAction<any | undefined>(UPDATE_CATEGORY_SUCCESS); // Action creator for successful category update
export const deleteCategory = createAction<any | undefined>(DELETE_CATEGORY); // Action creator for deleting a category
export const deleteCategorySuccess = createAction<any | undefined>(DELETE_CATEGORY_SUCCESS); // Action creator for successful category deletion

export const retrieveCategories = createAction<any | undefined>(RETRIEVE_CATEGORIES); // Action creator for retrieving categories
export const retrieveCategoriesSuccess = createAction<any | undefined>(RETRIEVE_CATEGORIES_SUCCESS); // Action creator for successful category retrieval
export const retrieveCategoriesFailure = createAction<any | undefined>(RETRIEVE_CATEGORIES_FAIL); // Action creator for handling failed category retrieval
