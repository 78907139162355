import { take, call, put } from "redux-saga/effects";
import {
  retrieveSettings,
  retrieveSettingsFailure,
  retrieveSettingsSuccess,
} from "../actions/Settings";
import { RETRIEVE_SETTINGS_PATH } from "../constants/Settings";
import { endSession } from "../../utils/commonUtils";
import ApiInvoke from "utils/apiFetch";

// Function to get backend endpoint for settings
const backendEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_COMMON_BACKEND_DEV}/${path}`
    : process.env.REACT_APP_ENV === "staging"
    ? `${process.env.REACT_APP_COMMON_BACKEND_STAGING}/${path}`
    : `${process.env.REACT_APP_COMMON_BACKEND_QA}/${path}`;

// Function to retrieve settings from backend
export function* retrieveSettingsAPI() {
  while (true) {
    yield take(`${retrieveSettings}`);

    const { payload, err } = yield call(
      ApiInvoke,
      backendEndpoint(
        `${RETRIEVE_SETTINGS_PATH}${
          process.env.REACT_APP_ENV === "staging"
            ? process.env.REACT_APP_SETTINGS_VERSION_STAGING
            : process.env.REACT_APP_SETTINGS_VERSION
        }`
      ),
      "",
      "GET"
    );

    if (payload && !err) {
      yield put(retrieveSettingsSuccess(payload));
      continue;
    }
    endSession(err);
    yield put(retrieveSettingsFailure(err));
  }
}
