import {
  CREATE_DEVICE,
  CREATE_DEVICE_SUCCESS,
  DELETE_DEVICE,
  DELETE_DEVICE_SUCCESS,
  RETRIEVE_DEVICE,
  RETRIEVE_DEVICES,
  RETRIEVE_DEVICES_FAIL,
  RETRIEVE_DEVICES_SUCCESS,
  UPDATE_DEVICE,
  UPDATE_DEVICE_SUCCESS,
  SEARCH_DEVICE_SUCCESS,
  SEARCH_DEVICE,
  CREATE_DEVICE_BULK,
  RETRIEVE_DEVICE_SUCCESS,
  DEVICE_BULK_UPLOAD,
  DEVICE_BULK_UPLOAD_STATUS,
  DEVICE_BULK_UPLOAD_STATUS_SUCCESS,
  GET_DEVICE_STATS,
  GET_DEVICE_STATS_SUCCESS,
  GET_DEVICE_STATS_FAILURE,
  GET_DEVICE_TIMELINE,
  GET_DEVICE_TIMELINE_SUCCESS,
  GET_DEVICE_TIMELINE_FAILURE,
  GET_DEVICE_UPLOAD_SAMPLE_CSV,
  GET_DEVICE_UPLOAD_SAMPLE_CSV_SUCCESS,
  GET_DEVICE_UPLOAD_SAMPLE_CSV_FAILURE,
  GET_TEE_PROPERTIES,
  GET_TEE_PROPERTIES_SUCCESS,
  GET_TEE_PROPERTIES_FAILURE,
  GET_TRANSACTION_LOGS,
  GET_TRANSACTION_LOGS_SUCCESS,
  GET_TRANSACTION_LOGS_FAILURE,
  ELIGIBILITY_SEARCH_DEVICE,
  ELIGIBILITY_SEARCH_DEVICE_SUCCESS,
} from "../constants/Device";

import { createAction } from "redux-act";

// Device
export const createDevice = createAction<any | undefined>(CREATE_DEVICE); // Action creator for creating a device
export const createDeviceSuccess = createAction<any | undefined>(CREATE_DEVICE_SUCCESS); // Action creator for successful device creation
export const deleteDevice = createAction<any | undefined>(DELETE_DEVICE); // Action creator for deleting a device
export const deleteDeviceSuccess = createAction<any | undefined>(DELETE_DEVICE_SUCCESS); // Action creator for successful device deletion
export const retrieveDevice = createAction<any | undefined>(RETRIEVE_DEVICE); // Action creator for retrieving a single device
export const retrieveDeviceSuccess = createAction<any | undefined>(RETRIEVE_DEVICE_SUCCESS); // Action creator for successful single device retrieval
export const retrieveDevices = createAction<any | undefined>(RETRIEVE_DEVICES); // Action creator for retrieving devices
export const retrieveDevicesSuccess = createAction<any | undefined>(RETRIEVE_DEVICES_SUCCESS); // Action creator for successful device retrieval
export const retrieveDevicesFailure = createAction<any | undefined>(RETRIEVE_DEVICES_FAIL); // Action creator for handling failed device retrieval
export const updateDevice = createAction<any | undefined>(UPDATE_DEVICE); // Action creator for updating a device
export const updateDeviceSuccess = createAction<any | undefined>(UPDATE_DEVICE_SUCCESS); // Action creator for successful device update
export const searchDevice = createAction<any | undefined>(SEARCH_DEVICE); // Action creator for searching devices
export const searchDeviceSuccess = createAction<any | undefined>(SEARCH_DEVICE_SUCCESS); // Action creator for successful device search
export const createDeviceBulk = createAction<any | undefined>(CREATE_DEVICE_BULK); // Action creator for creating devices in bulk

export const deviceBulkUpload = createAction<any | undefined>(DEVICE_BULK_UPLOAD); // Action creator for device bulk upload
export const deviceBulkUploadStatus = createAction<any | undefined>(DEVICE_BULK_UPLOAD_STATUS); // Action creator for getting device bulk upload status
export const deviceBulkUploadStatusSuccess = createAction<any | undefined>(
  DEVICE_BULK_UPLOAD_STATUS_SUCCESS
); // Action creator for successful retrieval of device bulk upload status

export const getDeviceUploadSampleCSV = createAction<any | undefined>(GET_DEVICE_UPLOAD_SAMPLE_CSV); // Action creator for getting a sample CSV file for device upload
export const getDeviceUploadSampleCSVSuccess = createAction<any | undefined>(
  GET_DEVICE_UPLOAD_SAMPLE_CSV_SUCCESS
); // Action creator for successful retrieval of sample CSV file
export const getDeviceUploadSampleCSVFailure = createAction<any | undefined>(
  GET_DEVICE_UPLOAD_SAMPLE_CSV_FAILURE
); // Action creator for handling failed retrieval of sample CSV file

export const getDeviceStats = createAction<any | undefined>(GET_DEVICE_STATS); // Action creator for getting device statistics
export const getDeviceStatsSuccess = createAction<any | undefined>(GET_DEVICE_STATS_SUCCESS); // Action creator for successful retrieval of device statistics
export const getDeviceStatsFailure = createAction<any | undefined>(GET_DEVICE_STATS_FAILURE); // Action creator for handling failed retrieval of device statistics

export const getDeviceTimeline = createAction<any | undefined>(GET_DEVICE_TIMELINE); // Action creator for getting device timeline
export const getDeviceTimelineSuccess = createAction<any | undefined>(GET_DEVICE_TIMELINE_SUCCESS); // Action creator for successful retrieval of device timeline
export const getDeviceTimelineFailure = createAction<any | undefined>(GET_DEVICE_TIMELINE_FAILURE); // Action creator for handling failed retrieval of device timeline

export const getTEEProperties = createAction<any | undefined>(GET_TEE_PROPERTIES); // Action creator for getting TEE (Trusted Execution Environment) properties
export const getTEEPropertiesSuccess = createAction<any | undefined>(GET_TEE_PROPERTIES_SUCCESS); // Action creator for successful retrieval of TEE properties
export const getTEEPropertiesFailure = createAction<any | undefined>(GET_TEE_PROPERTIES_FAILURE); // Action creator for handling failed retrieval of TEE properties

export const getTransactionLogs = createAction<any | undefined>(GET_TRANSACTION_LOGS); // Action creator for getting transaction logs
export const getTransactionLogsSuccess = createAction<any | undefined>(
  GET_TRANSACTION_LOGS_SUCCESS
); // Action creator for successful retrieval of transaction logs
export const getTransactionLogsFailure = createAction<any | undefined>(
  GET_TRANSACTION_LOGS_FAILURE
); // Action creator for handling failed retrieval of transaction logs

export const eligibilitySearchDevice = createAction<any | undefined>(ELIGIBILITY_SEARCH_DEVICE); // Action creator for eligibility search of devices
export const eligibilitySearchDeviceSuccess = createAction<any | undefined>(
  ELIGIBILITY_SEARCH_DEVICE_SUCCESS
); // Action creator for successful eligibility search of devices
