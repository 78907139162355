import { ConfigProvider } from "antd";
import { useState, createContext } from "react";
import { IntlProvider } from "react-intl";
import AppLocale from "../../lang";

export const LanguageContext = createContext({});

// Language Wrapper Component
const LanguageWrapper = (props) => {
  const direction = "ltr";

  // Fetch the user's language
  const local = navigator.language;
  //    const local = 'zh-SG';
  let appLocale = AppLocale["en"];

  // If the user's language is available in the AppLocale object, use it
  if (AppLocale[local.slice(0, 2)] != null) {
    appLocale = AppLocale[local.slice(0, 2)];
  }

  // Set the locale and current app locale
  const [locale, setLocale] = useState(local);
  const [currentAppLocale, setCurrentAppLocale] = useState(appLocale);

  // Function to select the app's locale
  function selectAppLocale(e) {
    //    const newLocale = e.target.value;
    const newLocale = e;
    setLocale(newLocale);
    if (AppLocale[newLocale.slice(0, 2)] != null) {
      setCurrentAppLocale(AppLocale[newLocale.slice(0, 2)]);
    }
  }

  // Return the LanguageContext.Provider component with the locale and selectAppLocale function as values
  return (
    <LanguageContext.Provider value={{ locale, selectAppLocale }}>
      <IntlProvider locale={locale} messages={currentAppLocale.messages}>
        <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
          {props.children}
        </ConfigProvider>
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
export default LanguageWrapper;
