export const CREATE_CERTIFICATE = "CREATE_CERTIFICATE";
export const CREATE_CERTIFICATE_SUCCESS = "CREATE_CERTIFICATE_SUCCESS";

export const RETRIEVE_CERTIFICATES = "RETRIEVE_CERTIFICATES";
export const RETRIEVE_CERTIFICATES_SUCCESS = "RETRIEVE_CERTIFICATES_SUCCESS";
export const RETRIEVE_CERTIFICATES_FAILIURE = "RETRIEVE_CERTIFICATES_FAILIURE";
export const RETRIEVE_CERTIFICATE = "RETRIEVE_CERTIFICATE";
export const RETRIEVE_CERTIFICATE_SUCCESS = "RETRIEVE_CERTIFICATE_SUCCESS";
export const RETRIEVE_CERTIFICATE_FAILIURE = "RETRIEVE_CERTIFICATE_FAILIURE";
export const RETRIEVE_CERTIFICATE_STATUS = "RETRIEVE_CERTIFICATE_STATUS";
export const RETRIEVE_CERTIFICATE_STATUS_SUCCESS =
  "RETRIEVE_CERTIFICATE_STATUS_SUCCESS";
export const RETRIEVE_CERTIFICATE_STATUS_FAILIURE =
  "RETRIEVE_CERTIFICATE_STATUS_FAILIURE";

export const SEARCH_CERTIFICATE_SUCCESS = "SEARCH_CERTIFICATE_SUCCESS";
export const SEARCH_CERTIFICATE = "SEARCH_CERTIFICATE";

///Certificate API paths
export const CREATE_CERTIFICATE_PATH = "certificate/enrollments";
export const FIND_ALL_CERTIFICATES_PATH = "certificate/enrollments";
export const FIND_ONE_CERTIFICATE_PATH = "certificate/enrollments/";
export const FIND_STATUS_CERTIFICATE_PATH = "/status";
export const SEARCH_CERTIFICATE_PATH = 'certificate/enrollments/advance/search';
