import { CategoryState } from "types/device";
import {
  CREATE_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  RETRIEVE_CATEGORIES,
  RETRIEVE_CATEGORIES_FAIL,
  RETRIEVE_CATEGORIES_SUCCESS,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
} from "../constants/Category";

const initialState: CategoryState = {
  // Initial state for categories
  loading: false, // Flag indicating if categories are being loaded
  error: false, // Flag indicating if an error occurred
  total: 0, // Total number of categories
  categories: [], // Array of category objects
  message: "", // Message related to category operations
};

function categoryReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CATEGORY:
      // Create category action
      return { ...state, loading: true };

    case CREATE_CATEGORY_SUCCESS:
      // Create category success action
      return {
        ...state,
        loading: false,
        categories: [...state.categories, payload.data],
        message: payload.message,
      };

    case RETRIEVE_CATEGORIES:
      // Retrieve categories action
      return { ...state, loading: true };

    case RETRIEVE_CATEGORIES_FAIL:
      // Failed to retrieve categories action
      return { ...state, loading: false, error: true, message: payload.data.message || "" };

    case RETRIEVE_CATEGORIES_SUCCESS:
      // Successfully retrieved categories action
      return { ...state, loading: false, categories: payload.data.category };

    case UPDATE_CATEGORY:
      // Update category action
      return {
        ...state,
        loading: true,
        categories: state.categories.map((category: any) => {
          if (category.id === payload.data.id) {
            return {
              ...category,
              ...payload.data,
            };
          } else {
            return category;
          }
        }),
      };

    case UPDATE_CATEGORY_SUCCESS:
      // Update category success action
      return { ...state, loading: false, message: payload.message };

    case DELETE_CATEGORY:
      // Delete category action
      return {
        ...state,
        categories: state.categories.map((category: any, index) => {
          if (category.id === payload.id) {
            return {
              ...state.categories.splice(index, 1),
            };
          } else {
            return category;
          }
        }),
      };

    default:
      return state;
  }
}

export default categoryReducer;
