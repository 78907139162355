import {
  SEARCH_USERS,
  SEARCH_USERS_SUCCESS,
  // GET_USERS,
  // GET_USERS_SUCCESS,
  // GET_USERS_FAILURE,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILIURE,
  RETRIEVE_USERS,
  RETRIEVE_USERS_SUCCESS,
  RETRIEVE_USERS_FAILURE,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  RETRIEVE_MFA_STATUS,
  RETRIEVE_MFA_STATUS_SUCCESS,
  RETRIEVE_MFA_STATUS_FAILURE,
} from "../constants/Users";

import { createAction } from "redux-act";

export const createUser = createAction<any | undefined>(CREATE_USER); // Action creator for creating a user
export const createUserSucces = createAction<any | undefined>(CREATE_USER_SUCCESS); // Action creator for successful user creation
export const retrieveUsers = createAction<any | undefined>(RETRIEVE_USERS); // Action creator for retrieving users
export const retrieveUsersSucces = createAction<any | undefined>(RETRIEVE_USERS_SUCCESS); // Action creator for successful user retrieval
export const retrieveUsersFailure = createAction<any | undefined>(RETRIEVE_USERS_FAILURE); // Action creator for handling failed user retrieval
export const deleteUser = createAction<any | undefined>(DELETE_USER); // Action creator for deleting a user
export const deleteUserSuccess = createAction<any | undefined>(DELETE_USER_SUCCESS); // Action creator for successful user deletion
export const deleteUserFailure = createAction<any | undefined>(DELETE_USER_FAILIURE); // Action creator for handling failed user deletion
export const searchUsers = createAction<any | undefined>(SEARCH_USERS); // Action creator for searching users
export const searchUsersSuccess = createAction<any | undefined>(SEARCH_USERS_SUCCESS); // Action creator for successful user search
export const updateUser = createAction<any | undefined>(UPDATE_USER); // Action creator for updating a user
export const updateUserSuccess = createAction<any | undefined>(UPDATE_USER_SUCCESS); // Action creator for successful user update
export const retrieveMfaStatus = createAction<any | undefined>(RETRIEVE_MFA_STATUS); // Action creator for retrieving mfa status
export const retrieveMfaStatusSucces = createAction<any | undefined>(RETRIEVE_MFA_STATUS_SUCCESS); // Action creator for successful mfa status retrieval
export const retrieveMfaStatusFailure = createAction<any | undefined>(RETRIEVE_MFA_STATUS_FAILURE); // Action creator for handling failed mfa status retrieval

// export const getUsers = createAction<any | undefined>(GET_USERS);
// export const getUsersSuccess = createAction<any | undefined>(GET_USERS_SUCCESS);
// export const getUsersFailure = createAction<any | undefined>(GET_USERS_FAILURE);
