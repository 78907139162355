import {
  RETRIEVE_SETTINGS,
  RETRIEVE_SETTINGS_FAIL,
  RETRIEVE_SETTINGS_SUCCESS,
} from "../constants/Settings";

import { createAction } from "redux-act";

// Settings
export const retrieveSettings = createAction<any | undefined>(RETRIEVE_SETTINGS); // Action creator for retrieving settings
export const retrieveSettingsSuccess = createAction<any | undefined>(RETRIEVE_SETTINGS_SUCCESS); // Action creator for successful settings retrieval
export const retrieveSettingsFailure = createAction<any | undefined>(RETRIEVE_SETTINGS_FAIL); // Action creator for handling failed settings retrieval
