export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const RETRIEVE_CATEGORIES_FAIL = 'RETRIEVE_CATEGORIES_FAIL';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';

export const RETRIEVE_CATEGORIES = 'RETRIEVE_CATEGORIES';
export const RETRIEVE_CATEGORIES_SUCCESS = 'RETRIEVE_CATEGORIES_SUCCESS';

export const RETRIEVE_CATEGORIES_PATH = 'device/category/all/list';
export const CREATE_CATEGORY_PATH = 'device/category';
export const UPDATE_CATEGORY_PATH = 'device/category/';
export const DELETE_CATEGORY_PATH = 'device/category/';
