import {
  RETRIEVE_SETTINGS,
  RETRIEVE_SETTINGS_FAIL,
  RETRIEVE_SETTINGS_SUCCESS,
} from "../constants/Settings";

const initialState = {
  loading: false, // Flag indicating if settings are being loaded
  error: false, // Flag indicating if an error occurred
  total: 0, // Total number of settings
  settings: [], // Array of settings
  message: "", // Message related to settings
};

function settingsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_SETTINGS:
      // Retrieve settings action
      return { ...state, loading: true };

    case RETRIEVE_SETTINGS_FAIL:
      // Failed to retrieve settings action
      return { ...state, loading: false, error: true, message: "Error while loading settings" };

    case RETRIEVE_SETTINGS_SUCCESS:
      // Successfully retrieved settings action
      return { ...state, loading: false, settings: payload.data };

    default:
      return state;
  }
}

export default settingsReducer;
