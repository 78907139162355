export const HomeConstants = {
  Devices: "home.Devices",
  Cryptos: "home.Cryptos",
  DeviceState: {
    New: "home.DeviceState.New",
    Active: "home.DeviceState.Active",
    Registered: "home.DeviceState.Registered",
    Terminated: "home.DeviceState.Terminated",
    Suspended: "home.DeviceState.Suspended",
  },
};

export const PermissionConstants = {
  Message: "permission.Message",
  Loading: "permission.Message",
  Granted: "permission.Msg.Granted",
  Denied: "permission.Msg.Denied",
  DeniedAction: "permission.Msg.Denied.Action",
};

export const ToggleMenuConstants = {
  User: "toggle.menu.User",
  Profile: "toggle.menu.Profile",
  Logout: "toggle.menu.Logout",
  DarkMode: "toggle.menu.DarkMode",
  Language: "toggle.menu.Language",
  LanguageEn: "toggle.menu.Language.English",
  LanguageFr: "toggle.menu.Language.French",
};
