import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Views from "./containers";
import "./assets/less/index.less";
import "./assets/less/dark-theme.less";
import "./assets/less/light-theme.less";
import "./assets/less/styles/styles.less";
import { fetchLoginState } from "./redux/actions/Auth";
import { retriveUserPermissions } from "redux/actions/Permissions";
import { stylesInjector } from "utils/stylesInjector";

/**
 * App component for the application.
 */
function App() {
  const dispatch = useDispatch();
  // Fetching login state from redux store
  const { isLoggedIn, isFetching } = useSelector((state: { auth: any }) => state.auth);

  // Fetch theme Switcher State
  const themeSwictherData = useSelector((state: { themeSwitch: any }) => state.themeSwitch);

  useEffect(() => {
    const { pathname } = document.location;

    // Dispatching action to fetch login state
    dispatch(fetchLoginState({ pathname: pathname }));

    // Retrieve user permissions if logged in
    if (!isFetching && isLoggedIn) {
      dispatch(retriveUserPermissions({}));
    }

    //Theme styles injector
    stylesInjector(themeSwictherData.currentTheme);
  }, [dispatch, isFetching, isLoggedIn, themeSwictherData.currentTheme]);

  return (
    <div className="App">
      {/* <Offline>
        <OfflinePage />
      </Offline> */}
      {/* <Online> */}
      <Router>
        <Switch>
          <Route path="/" component={Views} />
        </Switch>
      </Router>
      {/* </Online> */}
    </div>
  );
}

export default App;
