import {
  CREATE_ELIGIBILITY_RULE,
  CREATE_ELIGIBILITY_RULE_SUCCESS,
  DELETE_ELIGIBILITY_RULE,
  DELETE_ELIGIBILITY_RULES,
  DELETE_ELIGIBILITY_RULE_SUCCESS,
  RETRIEVE_ELIGIBILITY_RULES,
  RETRIEVE_ELIGIBILITY_RULES_FAIL,
  RETRIEVE_ELIGIBILITY_RULES_SUCCESS,
  SEARCH_ELIGIBILITY_RULE,
  SEARCH_ELIGIBILITY_RULE_SUCCESS,
  UPDATE_ELIGIBILITY_RULE,
  UPDATE_ELIGIBILITY_RULE_SUCCESS,
  RETRIEVE_ELIGIBILITY_RULE,
  RETRIEVE_ELIGIBILITY_RULE_SUCCESS,
  DELETE_ELIGIBILITY_RULE_FAIL,
  GET_ELIGIBILITY_STATS,
  GET_ELIGIBILITY_STATS_SUCCESS,
  GET_ELIGIBILITY_STATS_FAILURE,
  CHANGE_ELIGIBILITY_STATUS,
  CHANGE_ELIGIBILITY_STATUS_SUCCESS,
  CHANGE_ELIGIBILITY_STATUS_FAILURE,
} from "../constants/Eligibility";

import { createAction } from "redux-act";

// Eligibility
export const createEligibility = createAction<any | undefined>(CREATE_ELIGIBILITY_RULE); // Action creator for creating an eligibility rule
export const createEligibilitySuccess = createAction<any | undefined>(
  CREATE_ELIGIBILITY_RULE_SUCCESS
); // Action creator for successful eligibility rule creation
export const updateEligibility = createAction<any | undefined>(UPDATE_ELIGIBILITY_RULE); // Action creator for updating an eligibility rule
export const updateEligibilitySuccess = createAction<any | undefined>(
  UPDATE_ELIGIBILITY_RULE_SUCCESS
); // Action creator for successful eligibility rule update
export const deleteEligibility = createAction<any | undefined>(DELETE_ELIGIBILITY_RULE); // Action creator for deleting an eligibility rule
export const deleteEligibilityRules = createAction<any | undefined>(DELETE_ELIGIBILITY_RULES); // Action creator for deleting multiple eligibility rules
export const deleteEligibilitySuccess = createAction<any | undefined>(
  DELETE_ELIGIBILITY_RULE_SUCCESS
); // Action creator for successful eligibility rule deletion
export const deleteEligibilityFailure = createAction<any | undefined>(DELETE_ELIGIBILITY_RULE_FAIL); // Action creator for handling failed eligibility rule deletion

export const retrieveEligibility = createAction<any | undefined>(RETRIEVE_ELIGIBILITY_RULES); // Action creator for retrieving eligibility rules
export const retrieveEligibilitySuccess = createAction<any | undefined>(
  RETRIEVE_ELIGIBILITY_RULES_SUCCESS
); // Action creator for successful eligibility rule retrieval
export const retrieveEligibilityRule = createAction<any | undefined>(RETRIEVE_ELIGIBILITY_RULE); // Action creator for retrieving a single eligibility rule
export const retrieveEligibilityRulesSuccess = createAction<any | undefined>(
  RETRIEVE_ELIGIBILITY_RULE_SUCCESS
); // Action creator for successful single eligibility rule retrieval
export const retrieveEligibilityFailure = createAction<any | undefined>(
  RETRIEVE_ELIGIBILITY_RULES_FAIL
); // Action creator for handling failed eligibility rule retrieval

export const searchEligibility = createAction<any | undefined>(SEARCH_ELIGIBILITY_RULE); // Action creator for searching eligibility rules
export const searchEligibilitySuccess = createAction<any | undefined>(
  SEARCH_ELIGIBILITY_RULE_SUCCESS
); // Action creator for successful eligibility rule search

export const getEligibilityStats = createAction<any | undefined>(GET_ELIGIBILITY_STATS); // Action creator for getting eligibility rule statistics
export const getEligibilityStatsSuccess = createAction<any | undefined>(
  GET_ELIGIBILITY_STATS_SUCCESS
); // Action creator for successful retrieval of eligibility rule statistics
export const getEligibilityStatsFailure = createAction<any | undefined>(
  GET_ELIGIBILITY_STATS_FAILURE
); // Action creator for handling failed retrieval of eligibility rule statistics

export const changeEligibilityStatus = createAction<any | undefined>(CHANGE_ELIGIBILITY_STATUS); // Action creator for changing eligibility rule status
export const changeEligibilityStatusSuccess = createAction<any | undefined>(
  CHANGE_ELIGIBILITY_STATUS_SUCCESS
); // Action creator for successful eligibility rule status change
export const changeEligibilityStatusFailure = createAction<any | undefined>(
  CHANGE_ELIGIBILITY_STATUS_FAILURE
); // Action creator for handling failed eligibility rule status change
