import { UsersState, TTransaction } from "types/user";
import {
  // GET_USERS,
  // GET_USERS_FAILURE,
  // GET_USERS_SUCCESS,
  SEARCH_USERS,
  SEARCH_USERS_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  RETRIEVE_USERS,
  RETRIEVE_USERS_SUCCESS,
  RETRIEVE_USERS_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  RETRIEVE_MFA_STATUS,
  RETRIEVE_MFA_STATUS_SUCCESS,
  RETRIEVE_MFA_STATUS_FAILURE,
} from "../constants/Users";

const initialState: UsersState = {
  loading: false,
  error: false,
  total: 0,
  message: "",
  user: [],
  usersData: [],
  selectedTransaction: {} as TTransaction,
  mfaLoading: false,
  mfaStatus: false,
};

function usersReducer(state = initialState, action) {
  const { type, payload } = action;
  console.debug(action); // Log the action for debugging purposes

  switch (type) {
    case CREATE_USER:
      // Creating a new user
      return {
        ...state,
        loading: true, // Set loading state to true
        message: payload.data.message, // Update the message with the message from the payload
      };
    case CREATE_USER_SUCCESS:
      // User creation successful
      return {
        ...state,
        loading: false, // Set loading state to false
        message: payload.data.message, // Update the message with the message from the payload
      };

    case SEARCH_USERS:
      // Searching for users
      return { ...state, loading: true }; // Set loading state to true

    case SEARCH_USERS_SUCCESS:
      // User search successful
      return {
        ...state,
        loading: false, // Set loading state to false
        rules: payload.data.rules, // Update the rules with the rules data from the payload
        total: payload.data.total, // Update the total with the total count from the payload
      };

    case RETRIEVE_USERS:
      // Retrieving users
      return { ...state, loading: true }; // Set loading state to true

    case RETRIEVE_USERS_SUCCESS:
      // Users retrieval successful
      return { ...state, loading: false, users: payload.data.users }; // Update the users with the users data from the payload

    case RETRIEVE_USERS_FAILURE:
      // Users retrieval failed
      return {
        ...state,
        loading: false, // Set loading state to false
        error: true, // Set error state to true
        message: payload.data.message || "", // Update the message with the message from the payload, or empty string if not available
      };

    case DELETE_USER:
      // Deleting a user
      return {
        ...state,
        loading: true, // Set loading state to true
        user: state.usersData.map((user: any, index) => {
          if (user.id === payload.id) {
            return {
              ...state.usersData.splice(index, 1),
            };
          } else {
            return user;
          }
        }),
      };

    case DELETE_USER_SUCCESS:
      // User deletion successful
      return { ...state, loading: false, users: payload.data.users }; // Set loading state to false, update the users with the users data from the payload

    case UPDATE_USER:
      // Updating a user
      return {
        ...state,
        loading: true, // Set loading state to true
        devices: state.usersData.map((user: any) => {
          if (user.id === payload.data.id) {
            return {
              ...user,
              ...payload.data,
            };
          } else {
            return user;
          }
        }),
      };

    case UPDATE_USER_SUCCESS:
      // User update successful
      return { ...state, loading: false, message: payload.message }; // Set loading state to false, update the message with the message from the payload

    case RETRIEVE_MFA_STATUS:
      // Retrieving MFA status
      return { ...state, mfaLoading: true }; // Set MFA loading state to true

    case RETRIEVE_MFA_STATUS_SUCCESS:
      // MFA status retrieval successful
      return { ...state, mfaLoading: false, mfaStatus: payload.data.result }; // Set MFA loading state to false, update MFA status with the result from the payload

    case RETRIEVE_MFA_STATUS_FAILURE:
      // MFA status retrieval failed
      return { ...state, mfaLoading: false, message: payload.message }; // Set MFA loading state to false, update the message with the message from the payload

    default:
      return state;
  }
}

export default usersReducer;
