import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import rootSaga from "../sagas";
import rootReducers from "../reducers";

export default function configureStore(preloadedState: any) {
  const sagaMiddleware = createSagaMiddleware(); // Create saga middleware
  const middlewares = [sagaMiddleware, thunk]; // Define the middlewares array with saga middleware and thunk

  const store = createStore(rootReducers, preloadedState, applyMiddleware(...middlewares)); // Create the store with root reducers, preloaded state, and applied middlewares

  sagaMiddleware
    .run(rootSaga) // Run the root saga
    .toPromise() // Convert the saga task to a promise
    .catch((e: any) => {
      console.debug({ message: e.message, source: "sagaError", stacktrace: e.sagaStack }); // Log the saga error details
      store.dispatch({ type: "ERROR", payload: e }); // Dispatch an error action with the saga error payload
    });

  return store; // Return the created store
}
