import React from "react";
import { APP_NAME } from "../../configs/appConfig";

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <span className="font-weight-semibold">{APP_NAME} All rights reserved.</span>{" "}
      </span>
    </footer>
  );
}
