import {
  RETRIEVE_USEGROUPS,
  RETRIEVE_USEGROUPS_SUCCESS,
  RETRIEVE_USEGROUPS_FAILIURE,
  CREATE_USER_GROUP,
  CREATE_USER_GROUP_SUCCESS,
  UPDATE_USER_GROUP,
  UPDATE_USER_GROUP_SUCCESS,
  DELETE_USER_GROUP,
  DELETE_USER_GROUP_SUCCESS,
} from "../constants/UserGroups";

import { createAction } from "redux-act";

export const retriveUserGroups = createAction<any | undefined>(RETRIEVE_USEGROUPS); // Action creator for retrieving user groups
export const retriveUserGroupsSuccess = createAction<any | undefined>(RETRIEVE_USEGROUPS_SUCCESS); // Action creator for successful user group retrieval
export const retriveUserGroupsFailiure = createAction<any | undefined>(RETRIEVE_USEGROUPS_FAILIURE);
export const createUserGroup = createAction<any | undefined>(CREATE_USER_GROUP); // Action creator for creating user group
export const createUserGroupSuccess = createAction<any | undefined>(CREATE_USER_GROUP_SUCCESS); // Action creator for successful user group creation
export const updateUserGroup = createAction<any | undefined>(UPDATE_USER_GROUP); // Action creator for updating user group
export const updateUserGroupSuccess = createAction<any | undefined>(UPDATE_USER_GROUP_SUCCESS); // Action creator for successful user group update
export const deleteUserGroup = createAction<any | undefined>(DELETE_USER_GROUP); // Action creator for deleting user group
export const deleteUserGroupSuccess = createAction<any | undefined>(DELETE_USER_GROUP_SUCCESS); // Action creator for successful user group deletion
