import {
  FAIL_FETCHING_API,
  FAIL_FETCHING_LOGIN_STATE,
  FAIL_FETCHING_USER,
  FETCH_USER,
  LOGIN,
  LOGOUT,
  FETCH_OTP_USER,
  FAIL_FETCHING_OTP_USER,
} from "redux/constants/Auth";

const initialState = {
  // Initial state for authentication
  isPrepared: false, // Flag indicating if the authentication is prepared
  isLoggedIn: false, // Flag indicating if the user is logged in
  user: {
    project: "",
  }, // User object with initial properties
  isFetching: false, // Flag indicating if the authentication is being fetched
  error: undefined, // Error object for authentication
  jwt: "", // JSON Web Token for authentication
  pathname: null, // Current pathname
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FAIL_FETCHING_LOGIN_STATE:
      // Failed to fetch login state action
      return { ...initialState, isPrepared: true, pathname: payload.pathname };
    case FETCH_USER:
      // Fetch user action
      return { ...state, isFetching: true, error: undefined };
    case FAIL_FETCHING_USER:
      // Failed to fetch user action
      return { ...state, isFetching: false, error: payload };
    case FETCH_OTP_USER:
      // Fetch OTP user action
      return { ...state, isFetching: true, error: undefined };
    case FAIL_FETCHING_OTP_USER:
      // Failed to fetch OTP user action
      return { ...state, isFetching: false, error: payload };
    case LOGIN:
      // Login action
      return {
        ...state,
        isPrepared: true,
        isLoggedIn: true,
        user: payload.user || state.user,
        isFetching: false,
        error: undefined,
        jwt: payload.jwt,
        pathname: payload.pathname,
      };
    case LOGOUT:
      // Logout action
      return { ...initialState, isPrepared: true };
    case FAIL_FETCHING_API:
      // Failed to fetch API action
      return { ...initialState, error: payload.err };
    default:
      return state;
  }
}

export default authReducer;
