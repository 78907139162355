import {
  RETRIEVE_PERMISSIONS,
  RETRIEVE_PERMISSIONS_SUCCESS,
  RETRIEVE_PERMISSIONS_FAILIURE,
  RETRIEVE_USER_PERMISSIONS,
  RETRIEVE_USER_PERMISSIONS_SUCCESS,
  RETRIEVE_USER_PERMISSIONS_FAILIURE,
  CREATE_PERMISSIONS,
  CREATE_PERMISSIONS_SUCCESS,
  CREATE_PERMISSIONS_FAILIURE,
  UPDATE_PERMISSIONS,
  UPDATE_PERMISSIONS_SUCCESS,
  UPDATE_PERMISSIONS_FAILIURE,
  DELETE_PERMISSIONS,
  DELETE_PERMISSIONS_SUCCESS,
  DELETE_PERMISSIONS_FAILIURE,
} from "../constants/Permissions";
import { createAction } from "redux-act";

export const retrivePermissions = createAction<any | undefined>(RETRIEVE_PERMISSIONS); // Action creator for retrieving permissions
export const retrivePermissionsSuccess = createAction<any | undefined>(
  RETRIEVE_PERMISSIONS_SUCCESS
); // Action creator for successful retrieval of permissions
export const retrivePermissionsFailiure = createAction<any | undefined>(
  RETRIEVE_PERMISSIONS_FAILIURE
); // Action creator for handling failed retrieval of permissions

export const retriveUserPermissions = createAction<any | undefined>(RETRIEVE_USER_PERMISSIONS); // Action creator for retrieving user permissions
export const retriveUserPermissionsSuccess = createAction<any | undefined>(
  RETRIEVE_USER_PERMISSIONS_SUCCESS
); // Action creator for successful retrieval of user permissions
export const retriveUserPermissionsFailiure = createAction<any | undefined>(
  RETRIEVE_USER_PERMISSIONS_FAILIURE
); // Action creator for handling failed retrieval of user permissions

export const createPermission = createAction<any | undefined>(CREATE_PERMISSIONS); // Action creator for creating a permission
export const createPermissionSucess = createAction<any | undefined>(CREATE_PERMISSIONS_SUCCESS); // Action creator for successful permission creation
export const createPermissionFailiure = createAction<any | undefined>(CREATE_PERMISSIONS_FAILIURE); // Action creator for handling failed permission creation

export const updatePermission = createAction<any | undefined>(UPDATE_PERMISSIONS); // Action creator for updating a permission
export const updatePermissionSuccess = createAction<any | undefined>(UPDATE_PERMISSIONS_SUCCESS); // Action creator for successful permission update
export const updatePermissionFailiure = createAction<any | undefined>(UPDATE_PERMISSIONS_FAILIURE); // Action creator for handling failed permission update

export const deletePermission = createAction<any | undefined>(DELETE_PERMISSIONS); // Action creator for deleting a permission
export const deletePermissionSuccess = createAction<any | undefined>(DELETE_PERMISSIONS_SUCCESS); // Action creator for successful permission deletion
export const deletePermissionFailiure = createAction<any | undefined>(DELETE_PERMISSIONS_FAILIURE); // Action creator for handling failed permission deletion
