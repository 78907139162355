import {
  CREATE_CRYPTO_ASSET,
  CREATE_CRYPTO_ASSET_SUCCESS,
  DELETE_CRYPTO_ASSET,
  DELETE_CRYPTO_ASSETS,
  DELETE_CRYPTO_ASSET_SUCCESS,
  RETRIEVE_CRYPTO_ASSETS,
  RETRIEVE_CRYPTO_ASSETS_FAIL,
  RETRIEVE_CRYPTO_ASSETS_SUCCESS,
  SEARCH_CRYPTO_ASSET,
  SEARCH_CRYPTO_ASSET_SUCCESS,
  UPDATE_CRYPTO_ASSET,
  UPDATE_CRYPTO_ASSET_SUCCESS,
  FILE_UPLOAD_INIT,
  RETRIEVE_CRYPTO_ASSET,
  RETRIEVE_CRYPTO_ASSET_SUCCESS,
  GET_CRYPTO_STATS,
  GET_CRYPTO_STATS_SUCCESS,
  GET_CRYPTO_STATS_FAILURE,
  DOWNLOAD_CRYPTO_ASSET,
  GET_AUDIT_LOGS,
  GET_AUDIT_LOGS_SUCCESS,
  GET_AUDIT_LOGS_FAILURE,
  RETRIEVE_SERVICE_PROVIDERS,
  RETRIEVE_SERVICE_PROVIDERS_SUCCESS,
  RETRIEVE_SERVICE_PROVIDERS_FAILURE,
  CRYPTO_BULK_UPLOAD,
  GET_CRYPTO_UPLOAD_SAMPLE_CSV,
  GET_CRYPTO_UPLOAD_SAMPLE_CSV_SUCCESS,
  GET_CRYPTO_UPLOAD_SAMPLE_CSV_FAILURE,
  CRYPTO_BINARY_UPLOAD_SIGNED,
  CRYPTO_BULK_UPLOAD_SUCCESS,
  CRYPTO_BULK_UPLOAD_FAILURE,
  ELIGIBILITY_SEARCH_CRYPTO_ASSET,
  ELIGIBILITY_SEARCH_CRYPTO_ASSET_SUCCESS,
} from "../constants/Crypto";

import { createAction } from "redux-act";

// Crypto
export const createCrypto = createAction<any | undefined>(CREATE_CRYPTO_ASSET); // Action creator for creating a crypto asset
export const createCryptoSuccess = createAction<any | undefined>(CREATE_CRYPTO_ASSET_SUCCESS); // Action creator for successful crypto asset creation
export const updateCrypto = createAction<any | undefined>(UPDATE_CRYPTO_ASSET); // Action creator for updating a crypto asset
export const updateCryptoSuccess = createAction<any | undefined>(UPDATE_CRYPTO_ASSET_SUCCESS); // Action creator for successful crypto asset update
export const deleteCrypto = createAction<any | undefined>(DELETE_CRYPTO_ASSET); // Action creator for deleting a crypto asset
export const deleteCryptoAssets = createAction<any | undefined>(DELETE_CRYPTO_ASSETS); // Action creator for deleting multiple crypto assets
export const deleteCryptoSuccess = createAction<any | undefined>(DELETE_CRYPTO_ASSET_SUCCESS); // Action creator for successful crypto asset deletion
export const cryptoBulkUpload = createAction<any | undefined>(CRYPTO_BULK_UPLOAD); // Action creator for bulk uploading crypto assets
export const cryptoBulkUploadSuccess = createAction<any | undefined>(CRYPTO_BULK_UPLOAD_SUCCESS); // Action creator for successful crypto asset bulk upload
export const cryptoBulkUploadFailure = createAction<any | undefined>(CRYPTO_BULK_UPLOAD_FAILURE); // Action creator for handling failed crypto asset bulk upload

export const retrieveCrypto = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSETS); // Action creator for retrieving crypto assets
export const retrieveCryptoSuccess = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSETS_SUCCESS); // Action creator for successful crypto asset retrieval
export const retrieveCryptoAsset = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSET); // Action creator for retrieving a single crypto asset
export const retrieveCryptoAssetSuccess = createAction<any | undefined>(
  RETRIEVE_CRYPTO_ASSET_SUCCESS
); // Action creator for successful single crypto asset retrieval
export const retrieveCryptoFailure = createAction<any | undefined>(RETRIEVE_CRYPTO_ASSETS_FAIL); // Action creator for handling failed crypto asset retrieval

export const retrieveServiceProviders = createAction<any | undefined>(RETRIEVE_SERVICE_PROVIDERS); // Action creator for retrieving service providers
export const retrieveServiceProvidersSuccess = createAction<any | undefined>(
  RETRIEVE_SERVICE_PROVIDERS_SUCCESS
); // Action creator for successful service provider retrieval
export const retrieveServiceProvidersFailure = createAction<any | undefined>(
  RETRIEVE_SERVICE_PROVIDERS_FAILURE
); // Action creator for handling failed service provider retrieval

export const getCryptoUploadSampleCSV = createAction<any | undefined>(GET_CRYPTO_UPLOAD_SAMPLE_CSV); // Action creator for getting a sample CSV file for crypto asset upload
export const getCryptoUploadSampleCSVSuccess = createAction<any | undefined>(
  GET_CRYPTO_UPLOAD_SAMPLE_CSV_SUCCESS
); // Action creator for successful retrieval of sample CSV file
export const getCryptoUploadSampleCSVFailure = createAction<any | undefined>(
  GET_CRYPTO_UPLOAD_SAMPLE_CSV_FAILURE
); // Action creator for handling failed retrieval of sample CSV file

export const searchCrypto = createAction<any | undefined>(SEARCH_CRYPTO_ASSET); // Action creator for searching crypto assets
export const searchCryptoSuccess = createAction<any | undefined>(SEARCH_CRYPTO_ASSET_SUCCESS); // Action creator for successful crypto asset search

export const fileUploadInit = createAction<any | undefined>(FILE_UPLOAD_INIT); // Action creator for initializing file upload
export const downloadCryptoAsset = createAction<any | undefined>(DOWNLOAD_CRYPTO_ASSET); // Action creator for downloading a crypto asset

export const getCryptoStats = createAction<any | undefined>(GET_CRYPTO_STATS); // Action creator for getting crypto asset statistics
export const getCryptoStatsSuccess = createAction<any | undefined>(GET_CRYPTO_STATS_SUCCESS); // Action creator for successful retrieval of crypto asset statistics
export const getCryptoStatsFailure = createAction<any | undefined>(GET_CRYPTO_STATS_FAILURE); // Action creator for handling failed retrieval of crypto asset statistics

export const getAuditLogs = createAction<any | undefined>(GET_AUDIT_LOGS); // Action creator for getting audit logs
export const getAuditLogsSuccess = createAction<any | undefined>(GET_AUDIT_LOGS_SUCCESS); // Action creator for successful retrieval of audit logs
export const getAuditLogsFailure = createAction<any | undefined>(GET_AUDIT_LOGS_FAILURE); // Action creator for handling failed retrieval of audit logs

export const downloadSignedBinary = createAction<any | undefined>(CRYPTO_BINARY_UPLOAD_SIGNED); // Action creator for downloading a signed binary

export const eligibilitySearchCrypto = createAction<any | undefined>(
  ELIGIBILITY_SEARCH_CRYPTO_ASSET
); // Action creator for eligibility search of crypto assets
export const eligibilitySearchCryptoSuccess = createAction<any | undefined>(
  ELIGIBILITY_SEARCH_CRYPTO_ASSET_SUCCESS
); // Action creator for successful eligibility search of crypto assets
