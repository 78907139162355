import { DeviceState, TDevice, TWorkflow } from "types/device";
import {
  CREATE_DEVICE,
  RETRIEVE_DEVICES,
  UPDATE_DEVICE,
  DELETE_DEVICE,
  RETRIEVE_DEVICES_FAIL,
  RETRIEVE_DEVICES_SUCCESS,
  CREATE_DEVICE_SUCCESS,
  UPDATE_DEVICE_SUCCESS,
  SEARCH_DEVICE,
  SEARCH_DEVICE_SUCCESS,
  CREATE_DEVICE_BULK,
  RETRIEVE_DEVICE_SUCCESS,
  DEVICE_BULK_UPLOAD_STATUS_SUCCESS,
  DEVICE_BULK_UPLOAD_STATUS,
  GET_DEVICE_STATS,
  GET_DEVICE_STATS_SUCCESS,
  GET_DEVICE_STATS_FAILURE,
  GET_DEVICE_TIMELINE,
  GET_DEVICE_TIMELINE_FAILURE,
  GET_DEVICE_TIMELINE_SUCCESS,
  GET_TEE_PROPERTIES,
  GET_TEE_PROPERTIES_SUCCESS,
  GET_TEE_PROPERTIES_FAILURE,
  GET_TRANSACTION_LOGS,
  GET_TRANSACTION_LOGS_FAILURE,
  GET_TRANSACTION_LOGS_SUCCESS,
  ELIGIBILITY_SEARCH_DEVICE,
  ELIGIBILITY_SEARCH_DEVICE_SUCCESS,
} from "../constants/Device";

const initialState: DeviceState = {
  // Initial state for devices
  loading: false, // Flag indicating if devices are being loaded
  error: false, // Flag indicating if an error occurred
  total: 0, // Total number of devices
  message: "", // Message related to device operations
  devices: [], // Array of device objects
  selectedDevice: {} as TDevice, // Selected device object
  workFlow: {} as TWorkflow, // Workflow related to devices
  bulkUploadStatus: [], // Array of bulk upload status
  stats: {}, // Statistics related to devices
  teeProperties: [], // Array of TEE properties
  transactionLogs: [], // Array of transaction logs
  transactionLogsLoading: false, // Flag indicating if transaction logs are being loaded
};

function deviceReducer(state = initialState, action) {
  const { type, payload } = action;

  console.debug(action);

  switch (type) {
    case CREATE_DEVICE:
      // Create device action
      return { ...state, loading: true };

    case CREATE_DEVICE_SUCCESS:
      // Create device success action
      return {
        ...state,
        loading: false,
        message: payload.data.message,
      };

    case RETRIEVE_DEVICES:
      // Retrieve devices action
      return { ...state, loading: true };

    case RETRIEVE_DEVICES_SUCCESS:
      // Successfully retrieved devices action
      return { ...state, devices: payload.data.device, loading: false };

    case UPDATE_DEVICE:
      // Update device action
      return {
        ...state,
        loading: true,
        devices: state.devices.map((device: any) => {
          if (device.id === payload.data.id) {
            return {
              ...device,
              ...payload.data,
            };
          } else {
            return device;
          }
        }),
      };

    case UPDATE_DEVICE_SUCCESS:
      // Update device success action
      return { ...state, loading: false, message: payload.message };

    case DELETE_DEVICE:
      // Delete device action
      return { ...state, loading: true };

    case RETRIEVE_DEVICES_FAIL:
      // Failed to retrieve devices action
      return { ...state, loading: false, error: true, message: payload.data.message || "" };

    case SEARCH_DEVICE:
      // Search device action
      return { ...state, loading: true };

    case SEARCH_DEVICE_SUCCESS:
      // Successfully searched devices action
      return { ...state, loading: false, devices: payload.data.devices };

    case CREATE_DEVICE_BULK:
      // Create devices in bulk action
      return { ...state, loading: true };

    case RETRIEVE_DEVICE_SUCCESS:
      // Successfully retrieved device action
      return { ...state, loading: false, selectedDevice: payload.data.device };

    case DEVICE_BULK_UPLOAD_STATUS:
      // Device bulk upload status action
      return { ...state, loading: true };

    case DEVICE_BULK_UPLOAD_STATUS_SUCCESS:
      // Successfully retrieved device bulk upload status action
      return { ...state, loading: false, bulkUploadStatus: payload.data.deviceUploadedLogs };

    case GET_DEVICE_STATS:
      // Get device statistics action
      return { ...state, loading: true };

    case GET_DEVICE_STATS_SUCCESS:
      // Successfully retrieved device statistics action
      return { ...state, loading: false, stats: payload };

    case GET_DEVICE_STATS_FAILURE:
      // Failed to retrieve device statistics action
      return { ...state, loading: false, error: true, message: payload.data.message || "" };

    case GET_DEVICE_TIMELINE:
      // Get device timeline action
      return { ...state, loading: true };

    case GET_DEVICE_TIMELINE_SUCCESS:
      // Successfully retrieved device timeline action
      return {
        ...state,
        loading: false,
        workFlow: payload.data.deviceWorkFlow,
      };

    case GET_DEVICE_TIMELINE_FAILURE:
      // Failed to retrieve device timeline action
      return { ...state, loading: false, error: true, message: payload.data.message || "" };

    case GET_TEE_PROPERTIES:
      // Get TEE properties action
      return { ...state, loading: true };

    case GET_TEE_PROPERTIES_FAILURE:
      // Failed to retrieve TEE properties action
      return { ...state, loading: false, error: true, message: payload.data.message || "" };

    case GET_TEE_PROPERTIES_SUCCESS:
      // Successfully retrieved TEE properties action
      return {
        ...state,
        loading: false,
        teeProperties: payload.data.deviceTeeProperties,
      };

    case GET_TRANSACTION_LOGS:
      // Get transaction logs action
      return { ...state, transactionLogsLoading: true };

    case GET_TRANSACTION_LOGS_FAILURE:
      // Failed to retrieve transaction logs action
      return {
        ...state,
        transactionLogsLoading: false,
        error: true,
        message: payload.data || "",
        transactionLogs: [],
      };

    case GET_TRANSACTION_LOGS_SUCCESS:
      // Successfully retrieved transaction logs action
      return {
        ...state,
        transactionLogsLoading: false,
        transactionLogs: payload.data,
      };

    case ELIGIBILITY_SEARCH_DEVICE:
      // Eligibility search device action
      return { ...state, loading: true };

    case ELIGIBILITY_SEARCH_DEVICE_SUCCESS:
      // Successfully performed eligibility search for devices action
      return { ...state, loading: false, devices: payload.data.devices };

    default:
      return state;
  }
}

export default deviceReducer;
