export const CREATE_ELIGIBILITY_RULE = 'CREATE_ELIGIBILITY_RULE';
export const CREATE_ELIGIBILITY_RULE_SUCCESS = 'CREATE_ELIGIBILITY_RULE_SUCCESS';
export const UPDATE_ELIGIBILITY_RULE = 'UPDATE_ELIGIBILITY_RULE';
export const UPDATE_ELIGIBILITY_RULE_SUCCESS = 'UPDATE_ELIGIBILITY_RULE_SUCCESS';
export const RETRIEVE_ELIGIBILITY_RULES_FAIL = 'RETRIEVE_ELIGIBILITY_RULES_FAIL';
export const DELETE_ELIGIBILITY_RULE = 'DELETE_ELIGIBILITY_RULE';
export const DELETE_ELIGIBILITY_RULES = 'DELETE_ELIGIBILITY_RULES';
export const DELETE_ELIGIBILITY_RULE_SUCCESS = 'DELETE_ELIGIBILITY_RULE_SUCCESS';
export const DELETE_ELIGIBILITY_RULE_FAIL = 'DELETE_ELIGIBILITY_RULE_FAIL';
export const SEARCH_ELIGIBILITY_RULE = 'SEARCH_ELIGIBILITY_RULE';
export const SEARCH_ELIGIBILITY_RULE_SUCCESS = 'SEARCH_ELIGIBILITY_RULE_SUCCESS';
export const RETRIEVE_ELIGIBILITY_RULE = 'RETRIEVE_ELIGIBILITY_RULE';
export const RETRIEVE_ELIGIBILITY_RULE_SUCCESS = 'RETRIEVE_ELIGIBILITY_RULE_SUCCESS';
export const RETRIEVE_ELIGIBILITY_RULES = 'RETRIEVE_ELIGIBILITY_RULES';
export const RETRIEVE_ELIGIBILITY_RULES_SUCCESS = 'RETRIEVE_ELIGIBILITY_RULES_SUCCESS';
export const GET_ELIGIBILITY_STATS = 'GET_ELIGIBILITY_STATS';
export const GET_ELIGIBILITY_STATS_SUCCESS = 'GET_ELIGIBILITY_STATS_SUCCESS';
export const GET_ELIGIBILITY_STATS_FAILURE = 'GET_ELIGIBILITY_STATS_FAILURE';
export const CHANGE_ELIGIBILITY_STATUS = 'CHANGE_ELIGIBILITY_STATUS';
export const CHANGE_ELIGIBILITY_STATUS_SUCCESS = 'CHANGE_ELIGIBILITY_STATUS_SUCCESS';
export const CHANGE_ELIGIBILITY_STATUS_FAILURE = 'CHANGE_ELIGIBILITY_STATUS_FAILURE';
export const RETRIEVE_ELIGIBILITY_RULES_PATH = 'eligibility/rule/all';
export const RETRIEVE_ELIGIBILITY_RULE_PATH = 'eligibility/rule/';
export const CREATE_ELIGIBILITY_RULE_PATH = 'eligibility/rule';
export const UPDATE_ELIGIBILITY_RULE_PATH = 'eligibility/rule/';
export const DELETE_ELIGIBILITY_RULE_PATH = 'eligibility/rule/';
export const DELETE_ELIGIBILITY_RULES_PATH = 'eligibility/rule/bulk/delete';
export const SEARCH_ELIGIBILITY_RULE_PATH = 'eligibility/rule/advance/search';
export const GET_ELIGIBILITY_STATS_PATH = 'eligibility/statistic/summary';
export const CHANGE_ELIGIBILITY_STATUS_PATH = 'eligibility/rule/update/status/';
