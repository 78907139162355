import { take, call, put } from "redux-saga/effects";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  ICognitoUserPoolData,
} from "amazon-cognito-identity-js";
import {
  fetchLoginState,
  failFetchingLoginState,
  fetchUser,
  failFetchingUser,
  login,
  clickLogout,
  logout,
  hello,
  fetchHello,
  failFetchingApi,
  fetchOTPUser,
} from "../actions/Auth";
import { AUTH_USER_TOKEN_KEY, USER_LOGIN_PATH } from "../constants/Auth";
import ApiInvoke from "utils/apiFetch";
import jwt_decode from "jwt-decode";
import { RETRIEVE_SETTINGS_PATH } from "../constants/Settings";
import { AES } from "crypto-js";

// Configurations for Cognito User Pool
const poolData: ICognitoUserPoolData = {
  UserPoolId: `${
    process.env.REACT_APP_ENV === "dev"
      ? process.env.REACT_APP_USER_POOL_ID
      : process.env.REACT_APP_ENV === "staging"
      ? process.env.REACT_APP_USER_POOL_ID_STAGING
      : process.env.REACT_APP_USER_POOL_ID_QA
  }`,
  ClientId: `${
    process.env.REACT_APP_ENV === "dev"
      ? process.env.REACT_APP_CLIENT_ID
      : process.env.REACT_APP_ENV === "staging"
      ? process.env.REACT_APP_CLIENT_ID_STAGING
      : process.env.REACT_APP_CLIENT_ID_QA
  }`,
};

const userPool = new CognitoUserPool(poolData);

// Function to generate the endpoint URL based on the environment
function endpoint(s) {
  return `${
    process.env.REACT_APP_ENV === "dev"
      ? process.env.REACT_APP_ENDPOINT
      : process.env.REACT_APP_ENV === "staging"
      ? process.env.REACT_APP_ENDPOINT_STAGING
      : process.env.REACT_APP_ENDPOINT_QA
  }/${s}`;
}

// Function to generate user endpoint URL based on the environment
const userEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_USERS_BACKEND_DEV}/${path}`
    : process.env.REACT_APP_ENV === "staging"
    ? `${process.env.REACT_APP_USERS_BACKEND_STAGING}/${path}`
    : `${process.env.REACT_APP_USERS_BACKEND_QA}/${path}`;

// Function to generate settings endpoint URL based on the environment
const settingsEndpoint = (path) =>
  process.env.REACT_APP_ENV === "dev"
    ? `${process.env.REACT_APP_COMMON_BACKEND_DEV}/${path}`
    : process.env.REACT_APP_ENV === "staging"
    ? `${process.env.REACT_APP_COMMON_BACKEND_STAGING}/${path}`
    : `${process.env.REACT_APP_COMMON_BACKEND_QA}/${path}`;

// Function to retrieve the session for a Cognito User
const getSession = (cognitoUser) =>
  new Promise((resolve, reject) => {
    // Retrieve the session
    cognitoUser.getSession((err, result) => {
      if (result) {
        // Retrieve the user attributes
        cognitoUser.getUserAttributes((error, attrs) => {
          if (error) {
            reject({ payload: null, error });
          } else {
            // Retrieve the JWT token
            // Check if the token is expired
            const jwttoken = result.getIdToken().getJwtToken();
            const decodedToken: any = jwt_decode(jwttoken);
            if (decodedToken["exp"] * 1000 < new Date().getTime()) {
              reject({ payload: null, error: "Token expired" });
            } else {
              // Construct the payload
              // The payload contains the user attributes and the JWT token
              const payload: any = {};
              payload.user = {};
              attrs.forEach((attr) => (payload.user[attr.Name] = attr.Value));

              payload.jwt = jwttoken;

              resolve({ payload });
            }
          }
        });
      } else {
        reject({ payload: null, err });
      }
    });
  });

// Function to login user with Cognito User Pool
const cognitoSignIn = (params) =>
  new Promise((resolve, reject) => {
    // Retrieve the email and password from the params
    const { email, password, history, otpCode } = params;
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    // Construct the Cognito User
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    // Authenticate the user
    // If the user has enabled MFA, the user will be prompted to enter the OTP code
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        // Retrieve the user attributes
        // Construct the payload with the user attributes and the JWT token
        cognitoUser.getUserAttributes(async (err, attrs: any) => {
          const payload: any = {};
          attrs.forEach((attr) => (payload[attr.Name] = attr.Value));
          payload.jwt = result.getIdToken().getJwtToken();
          resolve({ payload });
        });
      },
      onFailure: (err) => {
        console.log("Authenticate User Failed---", err);
        resolve({ payload: null, err });
      },
      totpRequired: (challengeName, challengeParameters) => {
        // If the user has enabled MFA, has to verify the OTP code
        if (challengeName === "SOFTWARE_TOKEN_MFA") {
          if (otpCode != null) {
            // If the OTP code is provided, send the OTP code to Cognito
            cognitoUser.sendMFACode(
              otpCode,
              {
                // If the OTP code is correct, the user will be authenticated
                onSuccess: (session, userConfirmationNecessary) => {
                  // Retrieve the user attributes
                  // Construct the payload with the user attributes and the JWT token
                  cognitoUser.getUserAttributes(async (err, attrs: any) => {
                    const payload: any = {};
                    attrs.forEach((attr) => (payload[attr.Name] = attr.Value));
                    payload.jwt = session.getIdToken().getJwtToken();
                    resolve({ payload });
                  });
                },
                onFailure: (err) => {
                  resolve({ payload: null, err });
                },
              },
              "SOFTWARE_TOKEN_MFA"
            );
          } else {
            // Navigate to the OTP page if the OTP code is not provided in login page
            // Encrypt the password before sending to OTP page
            const encryptedPassword = AES.encrypt(
              password,
              process.env.REACT_APP_SECRET_KEY ?? ""
            ).toString();
            history.push(
              `/auth/otp?username=${email}&password=${encodeURIComponent(encryptedPassword)}`
            );
          }
        }
      },
    });
  });

// Function to get the access token for a Cognito User
const getAccessToken = (cognitoUser) =>
  new Promise((resolve, reject) => {
    cognitoUser.getSession((err, result) => {
      if (result && result.isValid()) {
        const token = result.getAccessToken().getJwtToken();
        resolve({ token });
      } else {
        resolve({ token: null, err });
      }
    });
  });

// Function to sign out a Cognito User
const globalSignOut = (cognitoUser) =>
  new Promise((resolve, reject) => {
    cognitoUser.globalSignOut({
      onSuccess: (result) => {
        resolve({ result });
      },
      onFailure: (err) => {
        resolve({ result: null, err });
      },
    });
  });

// Function to fetch the login state
// If the user is logged in, the user will be redirected to the dashboard
export function* handleFetchLoginState() {
  while (true) {
    const action: any = yield take(`${fetchLoginState}`);

    // Retrieve the settings
    const { payload, err } = yield call(
      ApiInvoke,
      settingsEndpoint(
        `${RETRIEVE_SETTINGS_PATH}${
          process.env.REACT_APP_ENV === "staging"
            ? process.env.REACT_APP_SETTINGS_VERSION_STAGING
            : process.env.REACT_APP_SETTINGS_VERSION
        }`
      ),
      "",
      "GET"
    );

    if (payload && !err && payload.data) {
      // If the auth provider is Cognito, check if the user is logged in
      if (payload.data.authProvider === "cognito") {
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
          const { payload, err } = yield call(getSession, cognitoUser);

          // If the user is logged in, retrieve the access token
          // Otherwise, redirect the user to the login page and clear the local storage
          if (payload && !err) {
            yield put(login(Object.assign({}, payload, action.payload)));
            continue;
          } else {
            yield put(failFetchingLoginState(action.payload));
            handleLogout();
            continue;
          }
        } else {
          yield put(failFetchingLoginState(""));
        }
      } else {
        // If the auth provider is JWT, check if the JWT token is valid
        const jwtToken = localStorage.getItem(AUTH_USER_TOKEN_KEY);

        if (jwtToken) {
          const decodedToken: any = jwt_decode(jwtToken);

          // If the JWT token is expired then clear the local storage
          // Otherwise, retrieve the user attributes and the JWT token
          if (decodedToken["sub"]) {
            if (decodedToken["exp"] * 1000 < new Date().getTime()) {
              localStorage.removeItem(AUTH_USER_TOKEN_KEY);
              yield put(logout());
            } else {
              const payload: any = {};
              payload.user = {};

              payload.jwt = jwtToken;

              yield put(login(Object.assign({}, payload, action.payload)));
              continue;
            }
          } else {
            localStorage.removeItem(AUTH_USER_TOKEN_KEY);
            yield put(logout());
          }
        } else {
          yield put(failFetchingLoginState(""));
        }
      }
    } else {
      yield put(failFetchingLoginState(""));
    }
  }
}

// Function to handle the logout action
export function* handleLogout() {
  while (true) {
    yield take(`${clickLogout}`);

    // Retrieve the settings
    const { payload, err } = yield call(
      ApiInvoke,
      settingsEndpoint(
        `${RETRIEVE_SETTINGS_PATH}${
          process.env.REACT_APP_ENV === "staging"
            ? process.env.REACT_APP_SETTINGS_VERSION_STAGING
            : process.env.REACT_APP_SETTINGS_VERSION
        }`
      ),
      "",
      "GET"
    );

    localStorage.clear();

    if (payload && !err && payload.data) {
      // If the auth provider is Cognito, sign out the user
      // Then dispatch the logout action
      if (payload.data.authProvider === "cognito") {
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
          const { token, err } = yield call(getAccessToken, cognitoUser);

          if (token && !err) {
            const { result, err } = yield call(globalSignOut, cognitoUser);

            if (result && !err) {
              yield put(logout());
            }
          }
        }
      } else {
        yield put(logout());
      }
    } else {
      // If the settings cannot be retrieved, dispatch the logout action
      yield put(logout());
    }
    window.location.href = "/auth/login";
  }
}

// Function to handle the login action without OTP
export function* handleLogin() {
  while (true) {
    const action = yield take(`${fetchUser}`);
    const { email, password, history } = action.payload;

    if (email && password) {
      // Retrieve the settings
      const { payload, err } = yield call(
        ApiInvoke,
        settingsEndpoint(
          `${RETRIEVE_SETTINGS_PATH}${
            process.env.REACT_APP_ENV === "staging"
              ? process.env.REACT_APP_SETTINGS_VERSION_STAGING
              : process.env.REACT_APP_SETTINGS_VERSION
          }`
        ),
        "",
        "GET"
      );

      if (payload && !err && payload.data) {
        // If the auth provider is Cognito, sign in the user with the email and password
        if (payload.data.authProvider === "cognito") {
          const { payload, err } = yield call(cognitoSignIn, action.payload);

          if (!payload && err) {
            yield put(failFetchingUser(`${err.message}`));
            continue;
          }

          // If the user is logged in, update the access token
          localStorage.setItem(AUTH_USER_TOKEN_KEY, payload.jwt);
          yield put(login(payload));
          continue;
        } else {
          // If the auth provider is JWT, sign in the user with the email and password
          const { payload, err } = yield call(
            ApiInvoke,
            userEndpoint(USER_LOGIN_PATH),
            action.payload,
            "POST"
          );

          if (!payload && err) {
            yield put(failFetchingUser("Invalid username or password"));
            continue;
          } else {
            // If the user is logged in, retrieve the access token
            const decodedToken: any = jwt_decode(payload.accessToken);

            if (decodedToken && decodedToken["sub"]) {
              // If the user has enabled MFA, encrypt the password and redirect the user to the OTP page
              const isMfaEnabled = decodedToken["mfaEnabled"] ?? false;
              if (isMfaEnabled) {
                const encryptedPassword = AES.encrypt(
                  password,
                  process.env.REACT_APP_SECRET_KEY ?? ""
                ).toString();
                history.push(
                  `/auth/otp?username=${email}&password=${encodeURIComponent(encryptedPassword)}`
                );
              } else {
                // Otherwise, update the access token
                localStorage.setItem(AUTH_USER_TOKEN_KEY, payload.accessToken);
                yield put(login(payload));
                continue;
              }
            } else {
              yield put(failFetchingUser("Invalid access token"));
              continue;
            }
          }
        }
      }
    } else {
      yield put(failFetchingUser("Please set email and password"));
    }
  }
}

// Function to handle the login action with OTP
export function* handleOTPLogin() {
  while (true) {
    const action = yield take(`${fetchOTPUser}`);
    const { email, password } = action.payload;

    if (email && password) {
      // Retrieve the settings
      const { payload, err } = yield call(
        ApiInvoke,
        settingsEndpoint(
          `${RETRIEVE_SETTINGS_PATH}${
            process.env.REACT_APP_ENV === "staging"
              ? process.env.REACT_APP_SETTINGS_VERSION_STAGING
              : process.env.REACT_APP_SETTINGS_VERSION
          }`
        ),
        "",
        "GET"
      );

      if (payload && !err && payload.data) {
        // If the auth provider is Cognito, sign in the user with OTP code
        if (payload.data.authProvider === "cognito") {
          const { payload, err } = yield call(cognitoSignIn, action.payload);

          if (!payload && err) {
            yield put(failFetchingUser(`${err.message}`));
            continue;
          }

          // If the user is logged in, update the access token
          localStorage.setItem(AUTH_USER_TOKEN_KEY, payload.jwt);
          yield put(login(payload));
          continue;
        } else {
          // If the auth provider is JWT, sign in the user with OTP code
          const { payload, err } = yield call(
            ApiInvoke,
            userEndpoint(USER_LOGIN_PATH),
            action.payload,
            "POST"
          );

          if (!payload && err) {
            yield put(failFetchingUser("Invalid OTP Code"));
            continue;
          } else {
            // If the user is logged in, update the access token
            localStorage.setItem(AUTH_USER_TOKEN_KEY, payload.accessToken);
            yield put(login(payload));
            continue;
          }
        }
      }
    } else {
      yield put(failFetchingUser("Please provide valid login credentials"));
    }
  }
}

// Sample function to handle the API call
export function* handleApi() {
  while (true) {
    const action = yield take(`${hello}`);

    const { payload, err } = yield call(ApiInvoke, endpoint(action.payload.path), "", "POST");

    if (payload && !err) {
      yield put(fetchHello(payload));
      continue;
    }
    yield put(failFetchingApi(err));
  }
}
