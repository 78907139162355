import enLang from "./entries/en_US";
import frLang from "./entries/fr_FR";
import zhLang from "./entries/zh_CN";

const AppLocale = {
  en: enLang,
  zh: zhLang,
  fr: frLang,
};

export default AppLocale;
