import {
  TOGGLE_COLLAPSED_NAV,
  SIDE_NAV_STYLE_CHANGE,
  CHANGE_LOCALE,
  NAV_TYPE_CHANGE,
  TOP_NAV_COLOR_CHANGE,
  HEADER_NAV_COLOR_CHANGE,
  TOGGLE_MOBILE_NAV,
  SWITCH_THEME,
  DIRECTION_CHANGE,
} from "../constants/Theme";

export function toggleCollapsedNav(navCollapsed: any) {
  return {
    type: TOGGLE_COLLAPSED_NAV, // Action type for toggling the navigation collapse state
    navCollapsed, // The new navigation collapsed state value
  };
}

export function onNavStyleChange(sideNavTheme: any) {
  return {
    type: SIDE_NAV_STYLE_CHANGE, // Action type for changing the side navigation style
    sideNavTheme, // The new side navigation theme value
  };
}

export function onLocaleChange(locale: any) {
  return {
    type: CHANGE_LOCALE, // Action type for changing the locale
    locale, // The new locale value
  };
}

export function onNavTypeChange(navType: any) {
  return {
    type: NAV_TYPE_CHANGE, // Action type for changing the navigation type
    navType, // The new navigation type value
  };
}

export function onTopNavColorChange(topNavColor: any) {
  return {
    type: TOP_NAV_COLOR_CHANGE, // Action type for changing the top navigation color
    topNavColor, // The new top navigation color value
  };
}

export function onHeaderNavColorChange(headerNavColor: any) {
  return {
    type: HEADER_NAV_COLOR_CHANGE, // Action type for changing the header navigation color
    headerNavColor, // The new header navigation color value
  };
}

export function onMobileNavToggle(mobileNav: any) {
  return {
    type: TOGGLE_MOBILE_NAV, // Action type for toggling the mobile navigation
    mobileNav, // The new mobile navigation toggle value
  };
}

export function onSwitchTheme(currentTheme: any) {
  return {
    type: SWITCH_THEME, // Action type for switching the theme
    currentTheme, // The new current theme value
  };
}

export function onDirectionChange(direction: any) {
  return {
    type: DIRECTION_CHANGE, // Action type for changing the direction
    direction, // The new direction value
  };
}
