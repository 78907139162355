export const SIGNIN = "SIGNIN";
export const AUTHENTICATED = "AUTHENTICATED";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const AUTH_TOKEN = "auth_token";
export const SIGNIN_WITH_GOOGLE = "SIGNIN_WITH_GOOGLE";
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = "SIGNIN_WITH_GOOGLE_AUTHENTICATED";
export const SIGNIN_WITH_FACEBOOK = "SIGNIN_WITH_FACEBOOK";
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = "SIGNIN_WITH_FACEBOOK_AUTHENTICATED";
export const FETCH_USER = "FETCH_USER";
export const LOGIN = "LOGIN";
export const FAIL_FETCHING_USER = "FAIL_FETCHING_USER";
export const CLICK_LOGOUT = "CLICK_LOGOUT";
export const LOGOUT = "LOGOUT";
export const AUTH_USER_TOKEN_KEY = "tauJWT";
export const FETCH_LOGIN_STATE = "FETCH_LOGIN_STATE";
export const FAIL_FETCHING_LOGIN_STATE = "FAIL_FETCHING_LOGIN_STATE";
export const FAIL_FETCHING_API = "FAIL_FETCHING_API";
export const FETCH_OTP_USER = "FETCH_OTP_USER";
export const FAIL_FETCHING_OTP_USER = "FAIL_FETCHING_OTP_USER";

export const USER_LOGIN_PATH = "user/login";
