import React from "react";
import { connect } from "react-redux";
import SideNav from "../components/layout/SideNav";
import TopNav from "../components/layout/TopNav";
import MobileNav from "../components/layout/MobileNav";
import HeaderNav from "../components/layout/HeaderNav";
import PageHeader from "../components/layout/PageHeader";
import Footer from "../components/layout/Footer";
import AppViews from "../containers/app";
import { Layout, Grid } from "antd";

import navigationConfig from "../configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
} from "../constants/ThemeConstant";
import utils from "utils";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const getLayoutGutter = (isNavTop, isMobile, navCollapsed) => {
  if (isNavTop || isMobile) {
    return 0;
  }
  return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
};

const getLayoutDirectionGutter = (dir, isNavTop, isMobile, navCollapsed) => {
  if (dir === DIR_LTR) {
    return { paddingLeft: getLayoutGutter(isNavTop, isMobile, navCollapsed) };
  }
  if (dir === DIR_RTL) {
    return { paddingRight: getLayoutGutter(isNavTop, isMobile, navCollapsed) };
  }
  return { paddingLeft: getLayoutGutter(isNavTop, isMobile, navCollapsed) };
};

export const AppLayout = ({ navCollapsed, navType, location, direction }) => {
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile ? (
          <SideNav routeInfo={currentRouteInfo} pathName={location.pathname} />
        ) : null}
        <Layout className="app-layout" style={getLayoutDirectionGutter(direction)}>
          <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
            <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
